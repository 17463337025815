import * as React from 'react';

export enum FormRowFlex {
    Row,
    Column
}

export type FormRowProps = {
    label?: string;
    htmlFor?: string;
    children?: React.ReactNode;
    required?: boolean,
    flex: FormRowFlex
}

export const FormRow = (props: FormRowProps) => {
    return (
        <div className="field grid">
            <label className="col-12 mb-2 md:col-2 md:mb-0 md:justify-content-end md:text-right align-items-start" htmlFor={props.htmlFor}>
                <strong>{props.label}</strong> {props.required && <span className="ml-1 text-red-600">*</span>}
            </label>
            <div className="col-12 md:col-10">
                <div className={`flex ${props.flex === FormRowFlex.Row ? 'flex-row' : 'flex-column'} align-items-start`}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

FormRow.defaultProps = {
    flex: FormRowFlex.Column
}