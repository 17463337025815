import { Locales } from "./Locales";

export const Messages = {
    [Locales.POLISH]: {
        AboutHorseCare: "Zajmujemy się przewozem koni oraz innych zwierząt.",
        AboutUs: "O nas",
        Add: "Dodaj",
        Admin: "Admin",
        AddPlannedTransport: "Dodaj planowany transport",
        AddRouteWaypoint: "Dodaj przystanek",
        AdminPanel: "Panel administracyjny",
        AskForTransport: "Zapytaj o transport",
        AskForTransportFailureText: "Niestety, na ten moment nie planujemy trasy w tym kierunku. Poinformujemy Cię, gdy tylko pojawi się możliwość realizacji transportu.",
        AskForTransportHint: "Przejdź do formularza aby złożyć zapytanie o transport.",
        AskForTransportSuccessText: "Mamy dla Ciebie ofertę na wybranej trasie, skorzystaj z transportu w cenie od {price} zł. Pracownik naszej firmy skontaktuje się z Państwem najszybciej jak to możliwe.",
        BufferTolerance: "Wielkość obszaru",
        BufferToleranceHint: "Tolerancja wielkości obszaru matchowania trasy",
        CalculatedPrice: "Wyliczony koszt",
        CannotFindRouteBetweenGivenPoints: "Nie znaleziono trasy pomiędzy podanymi punktami",
        CombinedRoute: "Trasa łączona",
        CombinedRouteLength: "Długość trasy łączonej",
        CombinedRouteTooltip: "Pokaż trasę łączoną",
        Contact: "Kontakt",
        CustomBuffer: "Własny obszar",
        CustomBufferNotValid: "Narysowany obszar nie jest prawidłowy. Upewnij się, że linie się nie krzyżują",
        CustomerRoute: "Trasa klienta",
        CustomerRouteTooltip: "Pokaż trasę klienta",
        CustomerRouteLength: "Długość trasy klienta",
        Dashboard: "Pulpit",
        DatePlanned: "Planowana data",
        DatePlannedPlaceholder: "Podaj planowaną datę transportu",
        DateSubmitted: "Data zapytania",
        DefaultDistanceTolerance: "Domyślna wartość tolerancji wydłużenia trasy",
        DefaultBufferTolerance: "Domyślna wartość wielkości obszaru",
        DistanceTolerance: "Tolerancja wydłużenia trasy",
        DistanceToleranceHint: "Tolerancja maksymalnego wydłużenia trasy przy matchowaniu",
        Draw: "Rysuj",
        DrawingAlreadyActive: "Rysowanie już jest aktywne",
        Edit: "Edytuj",
        EditPlannedTransport: "Edytuj planowany transport",
        Erase: "Wyczyść",
        FieldDate: "Podaj poprawną datę",
        FieldMaxLength: "Podaj maksymalnie {0} znaków",
        FieldRequired: "Pole wymagane",
        FieldValidAddress: "Wybierz poprawny adres",
        FinishDrawingCustomBuffer: "Dokończ rysowanie bufora",
        Hide: "Ukryj",
        HomePage: "Strona główna",
        HorseCare: "HorseCare",
        IndividualOrCombinedRoute: "Trasa indywidualna / łączona",
        IndividualRoute: "Trasa indywidualna",
        IsAvailable: "Dostępny",
        Login: "Zaloguj",
        LoginProcessing: "Trwa przekierowanie do strony logowania...",
        LoginCallbackProcessing: "Trwa przekierowanie do strony głównej...",
        Logout: "Wyloguj",
        LogoutProcessing: "Wylogowywanie...",
        LogoutCallbackProcessing: "Trwa przekierowanie do strony głównej...",
        LogoutSuccessful: "Wylogowano",
        MakeAvailable: "Udostępnij",
        MakeUnavailable: "Ukryj",
        Map: "Mapa",
        MapOptions: "Opcje mapy",
        Match: "Dopasowanie",
        Matches: "Dopasowania",
        MatchesCounter: "Dopasowania ({count})",
        MatchType: "Typ dopasowania",
        No: "Nie",
        None: "brak",
        OperationSucceeded: "Operacja zakończona powodzeniem",
        PageNotFound: "Nie odnaleziono podanej strony",
        PageNotAuthorized: "Nie masz dostępu do podanej strony",
        PayloadDescription: "Przedmiot transportu",
        PayloadDescriptionPlaceholder: "Podaj krótki opis co ma być przedmiotem transportu",
        PhoneNumber: "Numer telefonu",
        PhoneNumberPlaceholder: "+48 123 456 789",
        PlannedTransport: "Planowany transport",
        PlannedTransports: "Planowane transporty",
        Register: "Zarejestruj",
        RateForCombinedTransportPerKm: "Stawka dla transportu łączonego",
        RateForIndividualTransportPerKm: "Stawka dla transportu indywidualnego",
        Route: "Trasa",
        RouteContainsSameOriginAndDestination: "Początek i koniec trasy powinny być w różnych miejscach",
        RouteContainsSomeDuplicatedWaypoints: "Trasa zawiera zduplikowane przystanki",
        RouteDestination: "Koniec trasy",
        RouteDestinationPlaceholder: "Podaj adres rozładunku",
        RouteElongation: "Wydłużenie trasy",
        RouteFrom: "Trasa z",
        RouteLength: "Długość trasy",
        RouteOrigin: "Początek trasy",
        RouteOriginPlaceholder: "Podaj adres załadunku",
        RouteSuccessfullySetToAvailable: "Trasa została pomyślnie udostępniona",
        RouteSuccessfullySetToUnavailable: "Trasa została pomyślnie ukryta",
        RouteTo: "Trasa do",
        RouteTotalLength: "Całkowita długość trasy",
        RouteWaypointPlaceholder: "Podaj adres przystanku",
        SelectAtLeastThreeVertices: "Wybierz co najmniej 3 wierzchołki",
        SelectValidRoute: "Wybierz poprawną trasę",
        SelectValidRouteAndBuffer: "Wybierz poprawną trasę i obszar",
        Save: "Zapisz",
        Send: "Wyślij",
        Settings: "Ustawienia",
        SettingsSaved: "Ustawienia zapisane",
        Show: "Pokaż",
        TimeWindow: "Przedział czasowy",
        TimeWindowPlaceholder: "Wybierz przedział",
        TimeWindow_Any: "dowolny",
        TimeWindow_WithinTwoWeeks: "w ciągu dwóch tygodni",
        TimeWindow_WithinWeek: "w ciągu tygodnia",
        TransportDescription: "Opis",
        TransportDescriptionPlaceholder: "Notatki dotyczące transportu",
        TransportRequest: "Zapytanie o transport",
        TransportRequests: "Zapytania o transport",
        TransportRoute: "Trasa transportu",
        TransportRouteTooltip: "Pokaż trasę transportu",
        TransportSavedSuccessfully: "Transport został pomyślnie zapisany",
        UnexpectedErrorOccurred: "Wystąpił nieoczekiwany błąd",
        UnexpectedErrorOccurredDuringBufferValidation: "Wystąpił nieoczekiwany błąd podczas walidacji obszaru",
        UnexpectedErrorOccurredWhenSearchingRouteInGoogleMaps: "Wystąpił nieoczekiwany błąd podczas wyszukiwania trasy w Google Maps",
        UnexpectedErrorOccurredWhenLoadingGoogleMaps: "Wystąpił nieoczekiwany błąd podczas wczytywania Google Maps",
        VisibileRoutes: "Widoczne trasy",
        Yes: "Tak"
    }
};

export const PrimeMessages = {
    [Locales.POLISH]: {
        "accept": "Tak",
        "reject": "Nie",
        "choose": "Wybierz",
        "upload": "Wyślij",
        "cancel": "Anuluj",
        "dayNames": ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
        "dayNamesShort": ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
        "dayNamesMin": ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
        "emptyMessage": "Brak wyników",
        "monthNames": ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
        "monthNamesShort": ["STY", "LUT", "MAR", "KWI", "MAJ", "CZE", "LIP", "SIE", "WRZ", "PAŹ", "LIS", "GRU"],
        "today": "Dziś",
        "clear": "Wyczyść",
        "weekHeader": "tydz.",
        "firstDayOfWeek": 0,
        "dateFormat": "dd/mm/yyyy",
        "weak": "Słaby",
        "medium": "Umiarkowany",
        "strong": "Silny",
        "passwordPrompt": "Podaj hasło"
    }
};