import * as React from 'react';
import { useIntl } from 'react-intl';
import { TransportType } from '../../api/models/TransportType';

export interface TransportTypeDescriptionProps {
    type: TransportType
}

export const TransportTypeDescription = (props: TransportTypeDescriptionProps) => {
    const intl = useIntl();

    const getTransportTypeDescription = (type: TransportType) => {
        switch (type) {
            case TransportType.Combined:
                return intl.formatMessage({ id: "CombinedRoute" });
            case TransportType.Individual:
                return intl.formatMessage({ id: "IndividualRoute" });
            default:
                return '-';
        }
    }

    return (
        <>
            {getTransportTypeDescription(props.type)}
        </>
    );
}