import { Coordinates } from "./Coordinates";

export class NamedCoordinates {
    name: string;
    lat: number;
    lng: number;

    constructor(name: string, lat: number, lng: number) {
        this.name = name;
        this.lat = lat;
        this.lng = lng;
    }

    static createFrom(name: string, point: Coordinates): NamedCoordinates {
        return new NamedCoordinates(name, point.lat, point.lng);
    }
}