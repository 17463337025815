import * as React from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class Contact extends Component {

    render() {
        return (
            <p><FormattedMessage id="Contact" /></p>
        );
    }
}
