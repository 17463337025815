import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../api/ApiService';
import { ConfigurationViewModel } from '../../api/models/ConfigurationViewModel';
import { TransportDetailsViewModel } from '../../api/models/TransportDetailsViewModel';
import preventSendForm from '../../helpers/FormUtilities';
import { AdminCard, LoadingStatus } from '../shared/AdminCard';
import { NotFoundError } from '../shared/errors/NotFoundError';
import { FormRow, FormRowFlex } from '../shared/FormRow';
import { FormRowError } from '../shared/FormRowError';
import { FormRowSkeleton } from '../shared/FormRowSkeleton';
import useCheckMobileScreen from '../shared/hooks/useCheckMobileScreen';
import { useToastContext } from '../shared/hooks/useToastContext';
import { DrawingMode } from '../shared/maps/elements/DrawableBufferComponent';
import { RouteData } from '../shared/maps/elements/RouteComponent';
import { WaypointData } from '../shared/maps/elements/WaypointComponent';
import { RouteViewer } from '../shared/maps/RouteViewer';
import { WaypointSelector } from '../shared/maps/WaypointSelector';
import { MessageDialog, MessageDialogProps } from '../shared/MessageDialog';
import { AdminPage } from './routing/AdminRoutes';

interface SaveTransportForm {
    description: string,
    datePlanned?: Date,
    origin: string,
    destination: string,
    bufferTolerance: number,
    distanceTolerance: number
};

export const SaveTransport = () => {
    const { id } = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const isMobile = useCheckMobileScreen();
    const toast = useToastContext();
    const apiService = new ApiService();

    const [status, setStatus] = useState(LoadingStatus.Loading);
    const [model, setModel] = useState<TransportDetailsViewModel>();
    const [config, setConfig] = useState<ConfigurationViewModel>();
    const [drawingMode, setDrawingMode] = useState(DrawingMode.None);
    const [message, setMessage] = useState<MessageDialogProps>({ isVisible: false });
    const [routes, setRoutes] = useState([RouteData.createEmpty()]);

    let values: SaveTransportForm = {
        description: model?.description || '',
        datePlanned: model?.datePlanned ? new Date(model.datePlanned) : null,
        origin: model?.origin.name || '',
        destination: model?.destination.name || '',
        bufferTolerance: model?.bufferTolerance || config?.defaultBufferTolerance,
        distanceTolerance: model?.distanceTolerance || config?.defaultDistanceTolerance
    }

    const validate = (values: SaveTransportForm) => {
        const errors: FormikErrors<SaveTransportForm> = {};
        if (!values.origin) {
            errors.origin = intl.formatMessage({ id: "FieldRequired" });
        }
        if (!values.destination) {
            errors.destination = intl.formatMessage({ id: "FieldRequired" });
        }
        return errors;
    }

    const asUtc = (date: Date): Date => {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    };

    const onSubmit = (values: SaveTransportForm, form: FormikHelpers<SaveTransportForm>) => {
        if (!routes[0].isValidAndExists() || !routes[0].buffer?.isValid()) {
            setMessage({ text: intl.formatMessage({ id: "SelectValidRouteAndBuffer" }), severity: 'warn' });
            form.setSubmitting(false);
            return;
        }

        if (routes[0].hasDuplicatedWaypoints()) {
            setMessage({ text: intl.formatMessage({ id: "RouteContainsSomeDuplicatedWaypoints" }), severity: 'warn' });
            form.setSubmitting(false);
            return;
        }

        if (!routes[0].buffer?.isCorrect) {
            setMessage({ text: intl.formatMessage({ id: "CustomBufferNotValid" }), severity: 'warn' });
            form.setSubmitting(false);
            return;
        }

        apiService.saveTransport({
            id: id,
            description: values.description,
            plannedAt: values.datePlanned ? asUtc(values.datePlanned) : null,
            waypoints: routes[0].waypoints.map(e => e.toNamedCoordinates()),
            buffer: routes[0].buffer.points,
            isCustomBuffer: routes[0].buffer.isCustom,
            bufferTolerance: values.bufferTolerance,
            distanceTolerance: values.distanceTolerance
        }).then(id => {
            toast.showSuccess({ detail: intl.formatMessage({ id: "TransportSavedSuccessfully" }) });
            navigate(AdminPage.ShowTransport(id));
        }).catch(error => {
            console.error(error);
            toast.showGenericError();
            form.setSubmitting(false);
        });
    }

    const addWaypoint = () => {
        routes[0].addWaypoint();
        setRoutes([...routes]);
    }

    const onWaypointChange = (data: WaypointData, index: number) => {
        routes[0].setWaypoint(data, index);
        setRoutes([...routes]);
    }

    const onWaypointDelete = (index: number) => {
        routes[0].deleteWaypoint(index);
        setRoutes([...routes]);
    }


    useEffect(() => {
        apiService.getConfiguration()
            .then(response => {
                setConfig(response);

                if (id) {
                    apiService.getTransport(id)
                        .then(response => {
                            setModel(response);
                            setRoutes([RouteData.createFrom(response.waypoints, response.path, response.buffer, true, 0)]);
                            setStatus(LoadingStatus.Loaded);
                        })
                        .catch(error => {
                            if (error instanceof NotFoundError) {
                                setStatus(LoadingStatus.NotFound);
                            } else {
                                console.error(error);
                                setStatus(LoadingStatus.Failed);
                            }
                        });
                } else {
                    setStatus(LoadingStatus.Loaded);
                }
            })
            .catch(error => {
                console.error(error);
                setStatus(LoadingStatus.Failed);
            });
    }, []);

    const skeleton = <>
        <FormRowSkeleton labelWidth={"3rem"} contentHeight={"5rem"} />
        <FormRowSkeleton labelWidth={"6rem"} contentWidth={"12rem"} contentHeight={"3rem"} />
        <FormRowSkeleton labelWidth={"6rem"} contentHeight={"3rem"} />
        <FormRowSkeleton labelWidth={"0"} contentWidth={"10rem"} contentHeight={"3rem"} />
        <FormRowSkeleton labelWidth={"5rem"} contentHeight={"3rem"} />
        <FormRowSkeleton labelWidth={"0"} contentHeight={"500px"} />
    </>

    const content = <>
        {config &&
            <Formik initialValues={values} validate={validate} onSubmit={onSubmit}>
                {(form: FormikProps<SaveTransportForm>) => (
                    <Form onSubmit={form.handleSubmit} onKeyDown={preventSendForm}>
                        <FormRow label={intl.formatMessage({ id: "TransportDescription" })} htmlFor="description">
                            <InputTextarea
                                name="description"
                                placeholder={intl.formatMessage({ id: "TransportDescriptionPlaceholder" })}
                                value={form.values.description}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                className="w-full" />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "DatePlanned" })} htmlFor="datePlanned">
                            <Calendar
                                name="datePlanned"
                                placeholder={intl.formatMessage({ id: "DatePlannedPlaceholder" })}
                                value={form.values.datePlanned}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                dateFormat="dd.mm.yy"
                                touchUI={isMobile} />
                            <FormRowError name="datePlanned" />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "RouteOrigin" })} htmlFor="origin" required>
                            <WaypointSelector
                                key={`0/${routes[0].waypoints.length}`}
                                name="origin"
                                placeholder={intl.formatMessage({ id: "RouteOriginPlaceholder" })}
                                value={form.values.origin}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                className="w-full"
                                waypointData={routes[0].waypoints[0]}
                                onWaypointChanged={(data) => onWaypointChange(data, 0)} />
                            <FormRowError name="origin" />
                        </FormRow>

                        {routes[0].waypoints.slice(1, routes[0].waypoints.length - 1).map((wpt, idx) =>
                            <FormRow key={`${idx + 1}/${routes[0].waypoints.length}`} flex={FormRowFlex.Row}>
                                <WaypointSelector
                                    name={`waypoint-${idx}/${routes[0].waypoints.length}`}
                                    placeholder={intl.formatMessage({ id: "RouteWaypointPlaceholder" })}
                                    onBlur={form.handleBlur}
                                    onChange={form.handleChange}
                                    className="w-full"
                                    waypointData={routes[0].waypoints[idx + 1]}
                                    onWaypointChanged={(data) => onWaypointChange(data, idx + 1)} />

                                <Button
                                    type="button"
                                    icon={PrimeIcons.TRASH}
                                    className="p-button-sm p-button-danger ml-1"
                                    onClick={() => onWaypointDelete(idx + 1)} />
                            </FormRow>)}

                        <FormRow>
                            <Button
                                type="button"
                                icon={PrimeIcons.PLUS}
                                label={intl.formatMessage({ id: "AddRouteWaypoint" })}
                                className="p-button-sm"
                                onClick={() => addWaypoint()} />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "RouteDestination" })} htmlFor="destination" required>
                            <WaypointSelector
                                key={`$n/${routes[0].waypoints.length}`}
                                name="destination"
                                placeholder={intl.formatMessage({ id: "RouteDestinationPlaceholder" })}
                                value={form.values.destination}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                className="w-full"
                                waypointData={routes[0].waypoints[routes[0].waypoints.length - 1]}
                                onWaypointChanged={(data) => onWaypointChange(data, routes[0].waypoints.length - 1)} />
                            <FormRowError name="destination" />
                        </FormRow>

                        <FormRow>
                            <RouteViewer
                                googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                routes={routes}
                                drawingMode={drawingMode}
                                onDrawingDone={() => setDrawingMode(DrawingMode.None)}
                                onDrawingError={(error) => { setMessage({ text: error, severity: 'warn' }); setDrawingMode(DrawingMode.None); }}
                                bufferTolerance={form.values.bufferTolerance}
                                onBufferCalculation={(points, tolerance) => apiService.calculateBuffer(points, tolerance)}
                                onBufferValidation={(points) => apiService.validateBuffer(points)}
                                onLoading={<i className={`${PrimeIcons.SPINNER} pi-spin`} style={{ fontSize: '2rem' }} />}
                                onLoadingError={<Message severity="error" text={intl.formatMessage({ id: "UnexpectedErrorOccurredWhenLoadingGoogleMaps" })} />}
                                onError={(error) => setMessage({ text: error, severity: 'warn' })}
                            />
                        </FormRow>

                        <Divider>
                            <FormattedMessage id="MapOptions" />
                        </Divider>

                        <FormRow label={intl.formatMessage({ id: "CustomBuffer" })}>
                            <div>
                                <Button
                                    type="button"
                                    icon={PrimeIcons.PENCIL}
                                    className="p-button-sm"
                                    label={intl.formatMessage({ id: "Draw" })}
                                    onClick={() => setDrawingMode(DrawingMode.Draw)}
                                    disabled={drawingMode === DrawingMode.Draw} />

                                <Button
                                    type="button"
                                    icon={PrimeIcons.TIMES}
                                    className="p-button-sm p-button-danger ml-1"
                                    label={intl.formatMessage({ id: "Erase" })}
                                    onClick={() => setDrawingMode(DrawingMode.Erase)} />
                            </div>
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "BufferTolerance" })} htmlFor="bufferTolerance">
                            <InputNumber
                                name="bufferTolerance"
                                placeholder={intl.formatMessage({ id: "BufferToleranceHint" })}
                                value={form.values.bufferTolerance}
                                min={config.minBufferTolerance} max={config.maxBufferTolerance}
                                onBlur={form.handleBlur}
                                onValueChange={form.handleChange}
                                showButtons
                                step={0.1} />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "DistanceTolerance" })} htmlFor="distanceTolerance">
                            <InputNumber
                                name="distanceTolerance"
                                placeholder={intl.formatMessage({ id: "DistanceToleranceHint" })}
                                value={form.values.distanceTolerance}
                                min={config.minDistanceTolerance} max={config.maxDistanceTolerance}
                                onBlur={form.handleBlur}
                                onValueChange={form.handleChange}
                                showButtons
                                suffix={"%"}
                                step={5} />
                        </FormRow>

                        <Divider />

                        <FormRow>
                            <Button
                                type="submit"
                                label={intl.formatMessage({ id: "Save" })}
                                loading={form.isSubmitting}
                                disabled={form.isSubmitting} />
                        </FormRow>
                    </Form>
                )}
            </Formik>}
    </>

    return (
        <>
            <AdminCard
                icon={PrimeIcons.CAR}
                title={intl.formatMessage({ id: id ? "EditPlannedTransport" : "AddPlannedTransport" })}
                skeleton={skeleton}
                content={content}
                status={status} />

            <MessageDialog
                {...message}
                onHide={() => setMessage({ isVisible: false })} />
        </>
    );
};