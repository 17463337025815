import { WaypointData } from "./WaypointComponent";

export class LabelHelper {
    static alphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    reassignLabels(waypoints: WaypointData[]) {
        this.#assignDefaultLabels(waypoints);
        this.#combineLabelsForSharedWaypoints(waypoints);
    }

    #assignDefaultLabels(waypoints: WaypointData[]) {
        let idx = 0;
        waypoints.forEach(wpt => {
            if (wpt.isValid()) {
                wpt.label = LabelHelper.alphabet[idx++];
            }
        });
    }

    #combineLabelsForSharedWaypoints(waypoints: WaypointData[]) {
        var updated = [];

        for (var i = 0; i < waypoints.length; i++) {
            if (updated.indexOf(i) < 0 && waypoints[i].isValid()) {
                var shared = [i];
                var sharedLabel = waypoints[i].label;

                for (var j = 0; j < waypoints.length; j++) {
                    if (i !== j && waypoints[j].isValid()
                        && waypoints[i].point.lat === waypoints[j].point.lat
                        && waypoints[i].point.lng === waypoints[j].point.lng) {
                        shared.push(j);
                        sharedLabel = sharedLabel.concat(waypoints[j].label);
                    }
                }

                for (var k = 0; k < shared.length; k++) {
                    waypoints[shared[k]].label = sharedLabel;
                    updated.push(shared[k]);
                }
            }
        }
    }

}