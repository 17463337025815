import { NamedCoordinates } from "./NamedCoordinates";

export interface ICoordinates {
    lat: number;
    lng: number;
}

export class Coordinates implements ICoordinates {
    lat: number;
    lng: number;

    constructor(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }

    static createFrom(latLng: google.maps.LatLng): Coordinates {
        return new Coordinates(latLng.lat(), latLng.lng());
    }

    static createArrayFrom(latLngs: google.maps.LatLng[]): Coordinates[] {
        return latLngs.map(latLng => this.createFrom(latLng));
    }

    static createFromNamed(coords: NamedCoordinates): Coordinates {
        return new Coordinates(coords.lat, coords.lng);
    }

    isValid(): boolean {
        return typeof (this.lat) === "number" && typeof (this.lng) === "number";
    }

    toLatLng(): google.maps.LatLng {
        return new google.maps.LatLng(this.lat, this.lng);
    }

    toLatLngString(): string {
        return `${this.lat},${this.lng}`;
    }
}

