import * as React from 'react';
import { AppRoute } from '../../../AppRouting';
import { AboutUs } from '../AboutUs';
import { AskForTransport } from '../AskForTransport';
import { Contact } from '../Contact';
import { Home } from "../Home";

const prefix = '';

export const HomePaths = {
    HomePrefix: prefix,
    Home: `${prefix}`,
    AboutUs: `${prefix}/AboutUs`,
    Contact: `${prefix}/Contact`,
    AskForTransport: `${prefix}/AskForTransport`,
};

export const HomePage = {
    Home: () => HomePaths.Home,
    AboutUs: () => HomePaths.AboutUs,
    Contact: () => HomePaths.Contact,
    AskForTransport: () => HomePaths.AskForTransport
};

const HomeRoutes: AppRoute[] = [
    {
        index: true,
        path: HomePaths.Home,
        element: <Home />
    },
    {
        path: HomePaths.AboutUs,
        element: <AboutUs />
    },
    {
        path: HomePaths.Contact,
        element: <Contact />
    },
    {
        path: HomePaths.AskForTransport,
        element: <AskForTransport />
    }
];

export default HomeRoutes;
