import * as React from 'react';
import { Link, To } from 'react-router-dom';

interface NavMenuProps {
    children?: React.ReactNode;
}

export const NavMenu = (props: NavMenuProps) => {
    return (
        <>
            <ul className="list-none p-0 m-0 flex align-items-center select-none border-none">
                {props.children}
            </ul>
        </>
    );
};

interface NavMenuIconProps {
    to: To;
    icon: string;
    label: string;
    replace?: boolean;
    state?: any;
}

export const NavMenuIcon = (props: NavMenuIconProps) => {
    return (
        <li>
            <Link to={props.to} state={props.state} replace={props.replace} className="flex p-2 align-items-center text-600 hover:text-900 hover:surface-100 no-underline font-medium border-round cursor-pointer transition-duration-150 transition-colors w-full">
                <i className={`${props.icon} text-base lg:text-2xl mr-2 lg:mr-0`}></i>
                <span className=" hidden lg:block pl-2 font-medium">{props.label}</span>
            </Link>
        </li>
    );
};