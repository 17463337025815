import * as React from 'react';
import { useIntl } from 'react-intl';

export interface YesNoDescriptionProps {
    value: boolean
}

export const YesNoDescription = (props: YesNoDescriptionProps) => {
    const intl = useIntl();

    return (
        <>
            {intl.formatMessage({ id: props.value ? "Yes" : "No" })}
        </>
    );
}