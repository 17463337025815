import * as React from 'react';
import { useIntl } from 'react-intl';
import { TimeWindow } from '../../api/models/TimeWindow';

export interface TimeWindowDescriptionProps {
    timeWindow: TimeWindow
}

export const TimeWindowDescription = (props: TimeWindowDescriptionProps) => {
    const intl = useIntl();

    const getTimeWindowDescription = (timeWindow: TimeWindow) => {
        switch (timeWindow) {
            case TimeWindow.Any:
                return intl.formatMessage({ id: "TimeWindow_Any" });
            case TimeWindow.WithinTwoWeeks:
                return intl.formatMessage({ id: "TimeWindow_WithinTwoWeeks" });
            case TimeWindow.WithinWeek:
                return intl.formatMessage({ id: "TimeWindow_WithinWeek" });
            default:
                return '-';
        }
    }

    return (
        <>
            {getTimeWindowDescription(props.timeWindow)}
        </>
    );
}