import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ApiService from '../../api/ApiService';
import { ConfigurationViewModel } from '../../api/models/ConfigurationViewModel';
import preventSendForm from '../../helpers/FormUtilities';
import { AdminCard, LoadingStatus } from '../shared/AdminCard';
import { FormRow } from '../shared/FormRow';
import { useToastContext } from '../shared/hooks/useToastContext';

export const Settings = () => {
    const intl = useIntl();
    const toast = useToastContext();
    const apiService = new ApiService();

    const [status, setStatus] = useState(LoadingStatus.Loading);
    const [model, setModel] = useState<ConfigurationViewModel>();

    const onSubmit = (values: ConfigurationViewModel, form: FormikHelpers<ConfigurationViewModel>) => {
        apiService.saveConfiguration({
            combinedTransportRatePerKm: values.combinedTransportRatePerKm,
            individualTransportRatePerKm: values.individualTransportRatePerKm,
            defaultBufferTolerance: values.defaultBufferTolerance,
            defaultDistanceTolerance: values.defaultDistanceTolerance
        }).then(() => {
            toast.showSuccess({ detail: intl.formatMessage({ id: "SettingsSaved" }) });
            form.setSubmitting(false);
        }).catch(error => {
            console.error(error);
            toast.showGenericError();
            form.setSubmitting(false);
        });
    }

    useEffect(() => {
        apiService.getConfiguration()
            .then(response => {
                setModel(response);
                setStatus(LoadingStatus.Loaded);
            })
            .catch(error => {
                console.error(error);
                setStatus(LoadingStatus.Failed);
            });
    }, []);

    const content = <>
        {model &&
            <Formik initialValues={model} onSubmit={onSubmit}>
                {(form: FormikProps<ConfigurationViewModel>) => (
                    <Form onSubmit={form.handleSubmit} onKeyDown={preventSendForm}>
                        <FormRow label={intl.formatMessage({ id: "RateForIndividualTransportPerKm" })} htmlFor="individualTransportRatePerKm">
                            <InputNumber
                                name="individualTransportRatePerKm"
                                value={form.values.individualTransportRatePerKm}
                                min={0}
                                onBlur={form.handleBlur}
                                onValueChange={form.handleChange}
                                showButtons
                                mode="currency"
                                currency="PLN"
                                suffix="/km" />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "RateForCombinedTransportPerKm" })} htmlFor="combinedTransportRatePerKm">
                            <InputNumber
                                name="combinedTransportRatePerKm"
                                value={form.values.combinedTransportRatePerKm}
                                min={0}
                                onBlur={form.handleBlur}
                                onValueChange={form.handleChange}
                                showButtons
                                mode="currency"
                                currency="PLN"
                                suffix="/km" />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "DefaultBufferTolerance" })} htmlFor="defaultBufferTolerance">
                            <InputNumber
                                name="defaultBufferTolerance"
                                value={form.values.defaultBufferTolerance}
                                min={form.values.minBufferTolerance}
                                max={form.values.maxBufferTolerance}
                                onBlur={form.handleBlur}
                                onValueChange={form.handleChange}
                                showButtons
                                step={0.05} />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "DefaultDistanceTolerance" })} htmlFor="defaultDistanceTolerance">
                            <InputNumber
                                name="defaultDistanceTolerance"
                                value={form.values.defaultDistanceTolerance}
                                min={form.values.minDistanceTolerance}
                                max={form.values.maxDistanceTolerance}
                                onBlur={form.handleBlur}
                                onValueChange={form.handleChange}
                                showButtons
                                suffix={"%"}
                                step={1} />
                        </FormRow>

                        <FormRow>
                            <Button
                                type="submit"
                                label={intl.formatMessage({ id: "Save" })}
                                loading={form.isSubmitting}
                                disabled={form.isSubmitting} />
                        </FormRow>
                    </Form>
                )}
            </Formik>}
    </>

    return (
        <AdminCard
            icon={PrimeIcons.COG}
            title={intl.formatMessage({ id: "Settings" })}
            content={content}
            status={status} />
    );
};