import { RouteData } from "./RouteComponent";

export class GoogleMapWrapper {
    ref: google.maps.Map;

    constructor(ref: HTMLElement) {
        this.ref = new google.maps.Map(ref, {});
    }

    addListener(event: string, callback: Function) {
        return this.ref.addListener(event, callback);
    }

    setOptions(options: google.maps.MapOptions) {
        this.ref.setOptions(options);
    }

    set(object: google.maps.Marker | google.maps.Polygon | google.maps.Polyline) {
        object.setMap(this.ref);
    }

    unset(object: google.maps.Marker | google.maps.Polygon | google.maps.Polyline) {
        if (object) {
            object.setMap(null);
        }
    }

    fitBoundsToRoutes(routes: RouteData[], minimalZoom: number) {
        // TODO: pass LatLng[] instead

        const bounds = new google.maps.LatLngBounds();
        let count = 0;

        for (var i = 0; i < routes.length; i++) {
            for (var j = 0; j < routes[i].waypoints.length; j++) {
                if (routes[i].waypoints[j].isValid()) {
                    bounds.extend(routes[i].waypoints[j].point.toLatLng());
                    count++;
                }
            }
        }

        if (count > 0) {
            this.ref.fitBounds(bounds);
            var zoom = this.ref.getZoom();
            this.ref.setZoom(zoom > minimalZoom ? minimalZoom : zoom);
        }
    }
}
