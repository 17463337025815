import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ListingViewModel } from '../../api/models/ListingViewModel';

interface AdminDataTableProps<TItem, TModel extends ListingViewModel<TItem>> {
    onFetch: (params: URLSearchParams) => Promise<TModel>;
    children?: React.ReactNode;
}

export const AdminDataTable = <TItem, TModel extends ListingViewModel<TItem>,>(props: AdminDataTableProps<TItem, TModel>) => {
    const parseFirstItemParam = (first: string, defaultFirst: number): number => {
        const value = parseInt(first);
        return (!isNaN(value) && value >= 0) ? value : defaultFirst;
    }

    const parsePageSizeParam = (size: string, defaultSize: number): number => {
        const value = parseInt(size);
        return availablePageSizes.indexOf(value) >= 0 ? value : defaultSize;
    }

    const onPage = (event: DataTablePFSEvent) => {
        setLoading(true);
        fetchItems(event.first, event.rows);
    }

    const fetchItems = (first: number, size: number) => {
        const params = new URLSearchParams(window.location.search);
        params.set('first', first.toString());
        params.set('size', size.toString());

        // TODO: async?
        props.onFetch(params).then(response => {
            setItems(response.items);
            setFirstItem(response.firstItem);
            setPageSize(response.pageSize);
            setTotalCount(response.totalCount);
            setSearchParams({ first: first.toString(), size: size.toString() }, { replace: true });
            setLoading(false);
        });
    }

    const defaultFirstItem = 0;
    const availablePageSizes = [10, 25, 50];
    const defaultPageSize = availablePageSizes[0];

    const [searchParams, setSearchParams] = useSearchParams();
    const [items, setItems] = useState<TItem[]>([]);
    const [firstItem, setFirstItem] = useState(parseFirstItemParam(searchParams.get('first'), defaultFirstItem));
    const [pageSize, setPageSize] = useState(parsePageSizeParam(searchParams.get('size'), defaultPageSize));
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => fetchItems(firstItem, pageSize), []);

    return (
        <>
            <DataTable value={items} lazy paginator rows={pageSize} totalRecords={totalCount} first={firstItem}
                onPage={onPage} loading={loading} rowsPerPageOptions={availablePageSizes}>
                {props.children}
            </DataTable>
        </>
    );
};