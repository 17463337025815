import { WaypointData } from "./WaypointComponent";

export class DirectionsService {
    directionsService: google.maps.DirectionsService;

    constructor() {
        this.directionsService = new google.maps.DirectionsService();
    }

    // TODO: maybe return promise instead of passing all handlers?
    calculate(waypoints: WaypointData[],
        onPathFound: (e: google.maps.DirectionsRoute) => void,
        onPathNotFound: () => void,
        onError: (error: any) => void) {
        const origin = waypoints[0];
        const intermediate = waypoints
            .slice(1, waypoints.length - 1)
            .filter(e => e.isValid())
            .map(e => e.toDirectionsWaypoint());
        const destination = waypoints[waypoints.length - 1];

        if (origin.isValid() && destination.isValid()) {
            this.directionsService
                .route({
                    origin: origin.toLatLngString(),
                    destination: destination.toLatLngString(),
                    waypoints: intermediate,
                    optimizeWaypoints: false,
                    travelMode: google.maps.TravelMode.DRIVING
                })
                .then(function (directions) {
                    const route = directions.routes[0];
                    // TODO: what if we have more routes here? maybe print them all?, extend boundary
                    if (!route) {
                        onPathNotFound();
                    } else {
                        onPathFound(route);
                    }
                })
                .catch(function (error) {
                    if (error.code !== 'ZERO_RESULTS') {
                        onError(error);
                    } else {
                        onPathNotFound();
                    }
                });
        }
    }
}