import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { addLocale as addPrimeLocale, locale as setPrimeLocale } from 'primereact/api';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import * as React from 'react';
import { IntlProvider } from "react-intl";
import "./App.tsx.css";
import { AppRouting } from './AppRouting';
import { ToastContextProvider } from "./components/shared/hooks/useToastContext";
import { Locales } from "./i18n/Locales";
import { Messages, PrimeMessages } from "./i18n/Messages";

const App = () => {
    const locale = Locales.POLISH;

    addPrimeLocale(locale, PrimeMessages[locale]);
    setPrimeLocale(locale);

    return (
        <React.StrictMode>
            <IntlProvider messages={Messages[locale]} locale={locale} defaultLocale={locale}>
                <ToastContextProvider>
                    <AppRouting />
                </ToastContextProvider>
            </IntlProvider>
        </React.StrictMode>
    );
}

export default App;
