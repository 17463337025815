import * as React from "react";
import { BufferComponent, BufferData } from "./BufferComponent";
import { Coordinates } from "./Coordinates";
import { CustomMapOptions } from "./CustomMapOptions";
import { GoogleMapWrapper } from "./GoogleMapWrapper";
import { LabelHelper } from "./LabelHelper";
import { NamedCoordinates } from "./NamedCoordinates";
import { PathComponent, PathData } from "./PathComponent";
import { WaypointComponent, WaypointData } from "./WaypointComponent";

export class RouteData {
    waypoints: WaypointData[];
    path: PathData;
    buffer: BufferData;
    visible: boolean;
    index: number;
    #dirty: boolean;

    constructor(waypoints?: WaypointData[], path?: PathData, buffer?: BufferData, visible?: boolean, index?: number, dirty?: boolean) {
        this.waypoints = this.#ensureWaypoints(waypoints);
        this.path = path;
        this.buffer = buffer;
        this.visible = visible;
        this.index = index;
        this.#dirty = dirty;
        this.#assignLabels();
    }

    static createEmpty(): RouteData {
        return new RouteData([], null, null, true, 0, false);
    }

    static createFrom(waypoints: NamedCoordinates[], path: Coordinates[], buffer: Coordinates[], visible: boolean, index: number): RouteData {
        const route = new RouteData(waypoints.map(wpt => WaypointData.createFromCoords(wpt)),
            PathData.createFrom(path),
            BufferData.createFrom(buffer),
            visible,
            index,
            false);
        route.#assignLabels();
        return route;
    }

    addWaypoint() {
        const empty = WaypointData.createEmpty();
        this.waypoints.splice(this.waypoints.length - 1, 0, empty);
        this.#dirty = true;
        this.#assignLabels();
    }

    setWaypoint(data: WaypointData, index: number) {
        this.waypoints[index] = data;
        this.#dirty = true;
        this.#assignLabels();
    }

    deleteWaypoint(index: number) {
        this.waypoints.splice(index, 1);
        this.#dirty = true;
        this.#assignLabels();
    }

    setPath(path: PathData) {
        this.path = path;
        this.#dirty = false;
    }

    setBuffer(buffer: BufferData) {
        this.buffer = buffer;
        this.#dirty = false;
    }

    isDirty() {
        return this.#dirty;
    }

    isValid() {
        return this.waypoints.length >= 2
            && this.waypoints[0].isValid()
            && this.waypoints[this.waypoints.length - 1].isValid();
    }

    isValidAndExists() {
        return this.isValid()
            && this.path?.isValid();
    }

    hasDuplicatedWaypoints() {
        const validWaypoints = this.waypoints.filter(e => e.isValid());
        for (let i = 0; i < validWaypoints.length - 1; i++) {
            if (validWaypoints[i].point.lat === validWaypoints[i + 1].point.lat
                && validWaypoints[i].point.lng === validWaypoints[i + 1].point.lng)
                return true;
        }
        return false;
    }

    getHash() {
        let hash = 0, idx = 1;
        this.waypoints.forEach(wpt => {
            hash += (wpt.point.lat + wpt.point.lng) * idx;
            idx++;
        });
        return hash;
    }

    #ensureWaypoints(waypoints: WaypointData[]): WaypointData[] {
        const wpts: WaypointData[] = waypoints ?? [];

        if (wpts.length === 0) {
            wpts.push(WaypointData.createEmpty());
        }
        if (wpts.length === 1) {
            wpts.push(WaypointData.createEmpty());
        }

        return wpts;
    }

    #assignLabels() {
        const helper = new LabelHelper();
        helper.reassignLabels(this.waypoints);
    }
}

export interface RouteProps extends CustomMapOptions {
    map: GoogleMapWrapper,
    data: RouteData
}

export const RouteComponent = (props: RouteProps): any => {
    return (
        <>
            {props.data.visible && <>
                {props.data.waypoints.map((wpt, idx) => <WaypointComponent map={props.map} key={idx} data={wpt} settings={props.markerSettings} zIndex={props.data.index} />)}
                {props.data.path && <PathComponent map={props.map} data={props.data.path} settings={props.pathSettings} />}
                {props.data.buffer && <BufferComponent map={props.map} data={props.data.buffer} settings={props.bufferSettings} />}
            </>}
        </>
    );
}