import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../api/ApiService';
import { RequestSummaryViewModel } from '../../api/models/RequestSummaryViewModel';
import { AdminCard } from '../shared/AdminCard';
import { AdminDataTable } from '../shared/AdminDataTable';
import { Ellipsis } from '../shared/Ellipsis';
import { YesNoDescription } from '../shared/YesNoDescription';
import { AdminPage } from './routing/AdminRoutes';

export const Requests = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const apiService = new ApiService();

    const content =
        <AdminDataTable onFetch={params => apiService.getRequests(params)}>
            <Column
                field="dateSubmitted"
                header={intl.formatMessage({ id: "DateSubmitted" })}
                body={(row: RequestSummaryViewModel) => intl.formatDate(row.dateSubmitted)} />

            <Column
                field="description"
                header={intl.formatMessage({ id: "PayloadDescription" })}
                body={(row: RequestSummaryViewModel) => <Ellipsis value={row.description ? row.description : '-'} />} />

            <Column
                field="routeOrigin"
                header={intl.formatMessage({ id: "RouteOrigin" })}
                body={(row: RequestSummaryViewModel) => <Ellipsis value={row.routeOrigin} />} />

            <Column
                field="routeDestination"
                header={intl.formatMessage({ id: "RouteDestination" })}
                body={(row: RequestSummaryViewModel) => <Ellipsis value={row.routeDestination} />} />

            <Column
                header={intl.formatMessage({ id: "Matches" })}
                body={(row: RequestSummaryViewModel) => <YesNoDescription value={row.hasMatches} />} />

            <Column
                body={(row: RequestSummaryViewModel) =>
                    <Button
                        className="p-button-sm"
                        icon={PrimeIcons.SEARCH}
                        tooltipOptions={{ position: "top" }}
                        tooltip={intl.formatMessage({ id: "Show" })}
                        onClick={() => navigate(AdminPage.ShowRequest(row.id))} />} />
        </AdminDataTable>

    return (
        <AdminCard
            icon={PrimeIcons.SHOPPING_CART}
            title={intl.formatMessage({ id: "TransportRequests" })}
            content={content} />
    );
};