import { useEffect, useState } from "react";
import { Coordinates } from "./Coordinates";
import { GoogleMapWrapper } from "./GoogleMapWrapper";
import { NamedCoordinates } from "./NamedCoordinates";

export class WaypointData {
    title: string;
    label: string;
    point: Coordinates;

    constructor(title: string, label: string, point: Coordinates) {
        this.title = title;
        this.label = label;
        this.point = point;
    }

    static createEmpty(): WaypointData {
        return new WaypointData(null, null, new Coordinates(null, null));
    }

    static createFromPlace(place: google.maps.places.PlaceResult): WaypointData {
        return new WaypointData(place.formatted_address, null, Coordinates.createFrom(place.geometry.location));
    }

    static createFromLatLng(point: google.maps.LatLng): WaypointData {
        return new WaypointData(null, null, Coordinates.createFrom(point));
    }

    static createFromCoords(coords: NamedCoordinates): WaypointData {
        return new WaypointData(coords.name, null, Coordinates.createFromNamed(coords));
    }

    isValid(): boolean {
        return this.point?.isValid() ?? false;
    }

    toLatLngString(): string {
        return this.point?.toLatLngString();
    }

    toDirectionsWaypoint(): google.maps.DirectionsWaypoint {
        return {
            location: this.toLatLngString(),
            stopover: true
        };
    }

    toNamedCoordinates() {
        return NamedCoordinates.createFrom(this.title, this.point);
    }
}

export interface WaypointProps {
    map: GoogleMapWrapper,
    data: WaypointData,
    settings: google.maps.MarkerLabel,
    zIndex: number,
    onClick?: (event: google.maps.MapMouseEvent) => void
}

export const WaypointComponent = (props: WaypointProps): any => {
    const [marker, setMarker] = useState<google.maps.Marker>();
    const [clickListener, setClickListener] = useState<google.maps.MapsEventListener>();

    const isValid = () => {
        return marker && props.data.isValid();
    }

    const draw = (map: GoogleMapWrapper) => {
        if (isValid()) {
            marker.setOptions({
                position: props.data.point.toLatLng(),
                title: props.data.title,
                zIndex: props.zIndex
            });
            if (props.data.label) {
                marker.setLabel({ text: props.data.label, color: props.settings.color });
            }
            map.set(marker);
        } else {
            map.unset(marker);
        }
    }

    const destroy = () => {
        props.map.unset(marker);
        clickListener?.remove();
    }

    useEffect(() => {
        if (!marker) {
            const newMarker = new google.maps.Marker();
            if (props.onClick) {
                setClickListener(newMarker.addListener("click", props.onClick));
            }
            setMarker(newMarker);
        }
        return () => destroy();
    }, [marker]);

    useEffect(() => {
        draw(props.map);
    }, [marker, props]);

    return null;
}

WaypointComponent.defaultProps = {
    zIndex: 1
}