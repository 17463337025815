import { PrimeIcons } from "primereact/api";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavMenuIcon } from '../shared/NavMenu';
import authService from './AuthorizeService';
import { ApplicationPaths } from './routing/AuthConstants';

type LoginMenuProps = {
    action?: string
}

type LoginMenuState = {
    isAuthenticated: boolean,
    userName: string
}

export const LoginMenu = (props: LoginMenuProps) => {
    const intl = useIntl();

    const [state, setState] = useState<LoginMenuState>({
        isAuthenticated: false,
        userName: null
    });

    const populateState = async () => {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    const authenticatedView = (userName: string, profilePath: string, logoutPath: string, logoutState: any) => {
        return (
            <>
                <NavMenuIcon icon={PrimeIcons.USER} to={profilePath} label={userName} />
                <NavMenuIcon icon={PrimeIcons.SIGN_OUT} to={logoutPath} replace={true} state={logoutState} label={intl.formatMessage({ id: "Logout" })} />
            </>
        );
    }

    const anonymousView = (registerPath: string, loginPath: string) => {
        return (
            <>
                <NavMenuIcon icon={PrimeIcons.USER_PLUS} to={registerPath} label={intl.formatMessage({ id: "Register" })} />
                <NavMenuIcon icon={PrimeIcons.SIGN_IN} to={loginPath} label={intl.formatMessage({ id: "Login" })} />
            </>
        );
    }

    useEffect(() => {
        let subscription = authService.subscribe(() => populateState());
        populateState();

        return () => {
            subscription = authService.subscribe(() => populateState());
            populateState();
        }
    }, [])

    if (!state.isAuthenticated) {
        const registerPath = `${ApplicationPaths.Register}`;
        const loginPath = `${ApplicationPaths.Login}`;
        return anonymousView(registerPath, loginPath);
    } else {
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = `${ApplicationPaths.LogOut}`;
        const logoutState = { local: true };
        return authenticatedView(state.userName, profilePath, logoutPath, logoutState);
    }
};
