import { PrimeIcons } from 'primereact/api';
import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { LoginMenu } from '../../auth/LoginMenu';
import { NavMenu, NavMenuIcon } from '../../shared/NavMenu';
import { AdminSidebar } from './AdminSidebar';
import { AdminSidebarToggler } from './AdminSidebarToggler';

export const AdminLayout = () => {
    const intl = useIntl();
    const [visible, setVisible] = useState(false);

    return (
        <div className="min-h-screen flex relative lg:static surface-ground">
            <AdminSidebar visible={visible} onHide={() => setVisible(false)} />

            <div className="min-h-screen flex flex-column relative flex-auto">
                <div className="flex justify-content-between align-items-center px-3 surface-section shadow-2 relative lg:sticky lg:top-0 border-bottom-1 surface-border z-5" style={{ height: "60px" }}>
                    <div className="flex">
                        <AdminSidebarToggler onClick={() => setVisible(true)} />
                    </div>

                    <NavMenu>
                        <NavMenuIcon to="" icon={PrimeIcons.HOME} label={intl.formatMessage({ id: "HomePage" })} />
                        <LoginMenu />
                    </NavMenu>
                </div>
                <div className="p-2 lg:p-5 flex flex-column flex-auto">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
