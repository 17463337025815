import * as React from "react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Coordinates } from "./Coordinates";
import { GoogleMapWrapper } from "./GoogleMapWrapper";
import { WaypointComponent, WaypointData } from "./WaypointComponent";

export enum DrawingMode {
    None,
    Draw,
    Erase
}

export interface DrawableBufferProps {
    map: GoogleMapWrapper,
    drawingMode: DrawingMode,
    settings: google.maps.PolylineOptions,
    markerSettings: google.maps.MarkerLabel,
    onBufferCompleted: (points: Coordinates[]) => void,
    onBufferError: (error: string) => void
}

export const DrawableBufferComponent = (props: DrawableBufferProps): any => {
    const intl = useIntl();
    const [polyline, setPolyline] = useState<google.maps.Polyline>();
    const [marker, setMarker] = useState<WaypointData>();
    const [addPointListener, setAddPointListener] = useState<google.maps.MapsEventListener>();

    const isValid = () => {
        return !!polyline && polyline.getPath().getArray().length >= 2;
    }

    const closePolyline = () => {
        const path = polyline.getPath().getArray();
        path.push(path[0]);
        return Coordinates.createArrayFrom(path);
    }

    const completeAndReplaceBuffer = (event: google.maps.MapMouseEvent) => {
        if (isValid()) {
            var points = closePolyline();
            props.onBufferCompleted(points);
        } else {
            props.onBufferError(intl.formatMessage({ id: "SelectAtLeastThreeVertices" }));
        }
    }

    const addStartMarker = (latLng: google.maps.LatLng) => {
        setMarker(WaypointData.createFromLatLng(latLng));
    }

    const onClick = (event: google.maps.MapMouseEvent) => {
        const path = polyline.getPath();

        if (path.getLength() === 0) {
            addStartMarker(event.latLng);
        }

        path.push(event.latLng);
    }

    const init = (map: GoogleMapWrapper) => {
        polyline.setOptions({
            strokeColor: props.settings.strokeColor,
            strokeOpacity: props.settings.strokeOpacity,
            strokeWeight: props.settings.strokeWeight
        });

        map.set(polyline);

        setAddPointListener(map.addListener("click", onClick));
    }

    const destroy = () => {
        if (!!polyline) {
            polyline.getPath().clear();
            props.map.unset(polyline);
        }
        addPointListener?.remove();
        setMarker(null);
    }

    useEffect(() => {
        if (!polyline) {
            setPolyline(new google.maps.Polyline());
        }
        return () => destroy();
    }, [polyline]);

    useEffect(() => {
        if (!!polyline && props.drawingMode === DrawingMode.Draw) {
            init(props.map);
        }

        if (!!polyline && props.drawingMode !== DrawingMode.Draw) {
            destroy();
        }
    }, [polyline, props.drawingMode]);

    return (
        <>
            {props.drawingMode === DrawingMode.Draw && marker &&
                <WaypointComponent map={props.map} data={marker} settings={props.markerSettings} zIndex={0} onClick={completeAndReplaceBuffer} />
            }
        </>
    );
}