import { PrimeIcons } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { MessageSeverityType } from 'primereact/message';
import * as React from 'react';

export interface MessageDialogProps {
    text?: string | React.ReactNode,
    severity?: MessageSeverityType,
    isVisible?: boolean,
    onHide?: () => void
}

export const MessageDialog = (props: MessageDialogProps) => {
    const headers: { [key: string]: React.ReactNode } = {
        'info': <i className={`${PrimeIcons.INFO_CIRCLE} text-blue-500 pt-1`} style={{ fontSize: '1.5rem' }} />,
        'success': <i className={`${PrimeIcons.CHECK} text-green-500 pt-1`} style={{ fontSize: '1.5rem' }} />,
        'warn': <i className={`${PrimeIcons.EXCLAMATION_TRIANGLE} text-yellow-500 pt-1`} style={{ fontSize: '1.5rem' }} />,
        'error': <i className={`${PrimeIcons.TIMES_CIRCLE} text-red-500 pt-1`} style={{ fontSize: '1.5rem' }} />,
    }

    return (
        <Dialog header={headers[props.severity]} visible={props.isVisible} style={{ width: '50vw' }} onHide={props.onHide}>
            <p className="text-center">{props.text}</p>
        </Dialog>
    );
};

MessageDialog.defaultProps = {
    isVisible: true
}