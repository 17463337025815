import * as React from 'react';

export interface EllipsisProps {
    value: string,
    maxLength: number
}

export const Ellipsis = (props: EllipsisProps) => {

    return (
        <>
            {props.value?.length > props.maxLength + 3
                ? props.value.substring(0, props.maxLength) + "..."
                : props.value}
        </>
    );
}

Ellipsis.defaultProps = {
    maxLength: 75
}