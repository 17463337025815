import * as React from "react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { CustomMapOptions } from "./CustomMapOptions";
import { GoogleMapWrapper } from "./GoogleMapWrapper";

export interface MapComponentProps extends google.maps.MapOptions, CustomMapOptions {
    style: { [key: string]: string },
    onInitialized: (map: GoogleMapWrapper) => void,
    minimalZoom: number,
    children?: ReactNode
}

export const MapComponent = (props: MapComponentProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<GoogleMapWrapper>();

    useEffect(() => {
        if (ref.current && !map) {
            const wrapper = new GoogleMapWrapper(ref.current);
            setMap(wrapper);
            props.onInitialized(wrapper);
        }
    }, [ref, map]);

    useEffect(() => {
        if (map) {
            map.setOptions(props);
        }
    }, [map]);

    return (
        <>
            <div ref={ref} style={props.style} />
            {map && props.children}
        </>
    );
}