import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { HomePaths } from './routing/HomeRoutes';

export const Home = () => {
    const navigate = useNavigate();
    const intl = useIntl();

    return (
        <>
            <h1><FormattedMessage id="HorseCare" /></h1>
            <p><FormattedMessage id="AboutHorseCare" /></p>
            <Divider />
            <p><FormattedMessage id="AskForTransportHint" /></p>
            <Button
                label={intl.formatMessage({ id: "AskForTransport" })}
                onClick={() => navigate(HomePaths.AskForTransport)}>
            </Button>
        </>
    );
}