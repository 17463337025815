import * as React from 'react';
import { NamedCoordinates } from './maps/elements/NamedCoordinates';

export interface RouteWaypointBreadCrumbProps {
    waypoints: NamedCoordinates[]
}

export const RouteWaypointBreadCrumb = (props: RouteWaypointBreadCrumbProps) => {
    return (
        <>
            {props.waypoints?.map(wpt => wpt.name).join(' ➔ ') || '-'}
        </>
    );
}