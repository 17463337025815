import { Status, Wrapper } from "@googlemaps/react-wrapper";
import * as React from 'react';
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { BufferData } from "./elements/BufferComponent";
import { Coordinates } from "./elements/Coordinates";
import { CustomMapOptions } from "./elements/CustomMapOptions";
import { DirectionsService } from "./elements/DirectionsService";
import { DrawableBufferComponent, DrawingMode } from "./elements/DrawableBufferComponent";
import { GoogleMapWrapper } from "./elements/GoogleMapWrapper";
import { MapComponent } from "./elements/MapComponent";
import { PathData } from "./elements/PathComponent";
import { RouteComponent, RouteData } from "./elements/RouteComponent";

export interface RouteViewerProps extends CustomMapOptions {
    routes: RouteData[],
    isReadonly: boolean,
    onLoading: ReactNode,
    onLoadingError: ReactNode,
    onError?: (error: string) => void,
    drawingMode: DrawingMode,
    onDrawingDone?: () => void,
    onDrawingError?: (error: string) => void,
    bufferTolerance: number,
    onBufferCalculation?: (path: Coordinates[], tolerance: number) => Promise<Coordinates[]>;
    onBufferValidation?: (points: Coordinates[]) => Promise<boolean>;
    googleApiKey: string,
    minimalZoom: number
}

export const RouteViewer = (props: RouteViewerProps) => {
    const intl = useIntl();
    const [routes, setRoutes] = useState(props.routes);
    const [hash, setHash] = useState<number>();
    const [map, setMap] = useState<GoogleMapWrapper>();
    const [drawingMode, setDrawingMode] = useState(DrawingMode.None);

    const onInitialized = (map: GoogleMapWrapper) => {
        setMap(map);
        if (routes[0].isValid()) {
            map.fitBoundsToRoutes(routes, props.minimalZoom);
        }
    }

    const setPath = (path: PathData) => {
        routes[0].setPath(path);
        setRoutes([...routes]);
    }

    const resetPath = () => {
        routes[0].setPath(null);
        routes[0].setBuffer(null);
        setRoutes([...routes]);
    }

    const setBuffer = (buffer: BufferData) => {
        routes[0].setBuffer(buffer);
        setRoutes([...routes]);
    }

    const resetBuffer = () => {
        routes[0].setBuffer(BufferData.createEmpty());
        setRoutes([...routes]);
    }

    const recalculateBuffer = () => {
        if (props.onBufferCalculation && routes[0].isValidAndExists()) {
            props.onBufferCalculation(routes[0].path.points, props.bufferTolerance)
                .then(response => {
                    setBuffer(BufferData.createFromCalculation(response));
                })
                .catch(error => {
                    console.error(error);
                    if (props.onError) {
                        props.onError(intl.formatMessage({ id: "" }));
                    }
                    resetBuffer();
                });
        }
    }

    const onWaypointsChanged = () => {
        if (props.isReadonly)
            return;

        const current = routes[0].getHash();
        if (current === hash)
            return;

        setHash(current);

        if (!routes[0].isDirty())
            return;

        if (routes[0].isValid()) {
            const directions = new DirectionsService();
            directions.calculate(
                routes[0].waypoints,
                (directions) => {
                    setPath(PathData.createFromDirections(directions));
                    recalculateBuffer();
                },
                () => {
                    if (props.onError) {
                        props.onError(intl.formatMessage({ id: "CannotFindRouteBetweenGivenPoints" }));
                    }

                    resetPath();
                },
                (error: any) => {
                    console.error(error);
                    if (props.onError) {
                        props.onError(intl.formatMessage({ id: "UnexpectedErrorOccurredWhenSearchingRouteInGoogleMaps" }));
                    }

                    resetPath();
                });
        } else {
            resetPath();
        }
    }

    const onCustomBufferDrawn = (points: Coordinates[]) => {
        setDrawingMode(DrawingMode.None);

        if (props.onBufferValidation) {
            props.onBufferValidation(points)
                .then(isCorrect => {
                    if (!isCorrect && props.onError) {
                        props.onError(intl.formatMessage({ id: "CustomBufferNotValid" }));
                    }

                    setBuffer(BufferData.createFromDrawing(points, isCorrect));
                })
                .catch((error) => {
                    console.error(error);
                    if (props.onError) {
                        props.onError(intl.formatMessage({ id: "UnexpectedErrorOccurredDuringBufferValidation" }));
                    }
                });
        } else {
            setBuffer(BufferData.createFromDrawing(points, true));
        }

        if (props.onDrawingDone) {
            props.onDrawingDone();
        }
    }

    const onDrawingModeChanged = () => {
        if (props.drawingMode === DrawingMode.Draw && drawingMode === DrawingMode.Draw && props.onDrawingError) {
            props.onDrawingError(intl.formatMessage({ id: "DrawingAlreadyActive" }));
        } else if (props.drawingMode === DrawingMode.Draw && !routes[0].isValidAndExists() && props.onDrawingError) {
            props.onDrawingError(intl.formatMessage({ id: "SelectValidRoute" }));
        } else {
            setDrawingMode(props.drawingMode);

            if (props.drawingMode === DrawingMode.Draw) {
                resetBuffer();
            } else if (props.drawingMode === DrawingMode.Erase) {
                recalculateBuffer();
            }
        }
    }

    const render = (status: Status): ReactElement => {
        if (status === Status.LOADING) return <>{props.onLoading}</>;
        if (status === Status.FAILURE) return <>{props.onLoadingError}</>;
        return null;
    };

    useEffect(() => {
        recalculateBuffer();
    }, [props.bufferTolerance]);

    useEffect(() => {
        onDrawingModeChanged();
    }, [props.drawingMode]);

    useEffect(() => {
        setRoutes([...props.routes]);
    }, [props.routes]);

    useEffect(() => {
        map?.fitBoundsToRoutes(routes, props.minimalZoom);
        onWaypointsChanged();
    }, [routes]);

    return (
        <>
            <Wrapper apiKey={props.googleApiKey} libraries={["places"]} render={render}>
                <MapComponent
                    onInitialized={onInitialized}
                    style={{ width: "100%", height: "500px" }}
                    {...props}>

                    {map && routes.map((route, idx) =>
                        <RouteComponent
                            map={map}
                            key={idx}
                            data={route}
                            markerSettings={props.markerSettings}
                            pathSettings={props.pathSettings}
                            bufferSettings={props.bufferSettings}
                            drawableBufferSettings={props.drawableBufferSettings} />)
                    }
                    {map && <DrawableBufferComponent
                        map={map}
                        drawingMode={drawingMode}
                        settings={props.drawableBufferSettings}
                        markerSettings={props.markerSettings}
                        onBufferCompleted={onCustomBufferDrawn}
                        onBufferError={props.onError}
                    />}
                </MapComponent>
            </Wrapper>
        </>
    );
}

RouteViewer.defaultProps = {
    center: {
        lat: 51.66222,
        lng: 19.45972,
    },
    zoom: 6,
    minimalZoom: 10,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    isReadonly: false,
    drawingMode: DrawingMode.None,
    bufferTolerance: 0.5,
    markerSettings: {
        text: "A",
        color: "white"
    },
    pathSettings: {
        strokeColor: "#0077b6",
        strokeOpacity: 0.75,
        strokeWeight: 6
    },
    bufferSettings: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.15
    },
    drawableBufferSettings: {
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 3
    }
}