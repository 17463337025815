import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../api/ApiService';
import { RequestSummaryViewModel } from '../../api/models/RequestSummaryViewModel';
import { TransportSummaryViewModel } from '../../api/models/TransportSummaryViewModel';
import { AdminCard } from '../shared/AdminCard';
import { AdminDataTable } from '../shared/AdminDataTable';
import { Ellipsis } from '../shared/Ellipsis';
import { YesNoDescription } from '../shared/YesNoDescription';
import { AdminPage } from './routing/AdminRoutes';

export const Transports = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const apiService = new ApiService();

    const actions =
        <Button
            className="p-button-sm"
            icon={PrimeIcons.PLUS}
            label={intl.formatMessage({ id: "AddPlannedTransport" })}
            onClick={() => navigate(AdminPage.AddTransport())}
        />

    const content =
        <AdminDataTable onFetch={params => apiService.getTransports(params)}>
            <Column
                field="datePlanned"
                header={intl.formatMessage({ id: "DatePlanned" })}
                body={(row: TransportSummaryViewModel) => row.datePlanned ? intl.formatDate(row.datePlanned) : '-'} />

            <Column
                field="description"
                header={intl.formatMessage({ id: "TransportDescription" })}
                body={(row: TransportSummaryViewModel) => <Ellipsis value={row.description ? row.description : '-'} />} />

            <Column
                field="routeOrigin"
                header={intl.formatMessage({ id: "RouteOrigin" })}
                body={(row: TransportSummaryViewModel) => <Ellipsis value={row.routeOrigin} />} />

            <Column
                field="routeDestination"
                header={intl.formatMessage({ id: "RouteDestination" })}
                body={(row: TransportSummaryViewModel) => <Ellipsis value={row.routeDestination} />} />

            <Column
                header={intl.formatMessage({ id: "IsAvailable" })}
                body={(row: TransportSummaryViewModel) => <YesNoDescription value={row.isAvailable} />} />

            <Column
                header={intl.formatMessage({ id: "Matches" })}
                body={(row: TransportSummaryViewModel) => <YesNoDescription value={row.hasMatches} />} />

            <Column
                style={{ minWidth: '9rem' }}
                body={(row: RequestSummaryViewModel) => <div>
                    <Button
                        className="p-button-sm"
                        icon={PrimeIcons.SEARCH}
                        tooltipOptions={{ position: "top" }}
                        tooltip={intl.formatMessage({ id: "Show" })}
                        onClick={() => navigate(AdminPage.ShowTransport(row.id))} />

                    <Button
                        className="p-button-sm ml-1"
                        icon={PrimeIcons.PENCIL}
                        tooltipOptions={{ position: "top" }}
                        tooltip={intl.formatMessage({ id: "Edit" })}
                        onClick={() => navigate(AdminPage.EditTransport(row.id))} />
                </div>} />
        </AdminDataTable>

    return (
        <AdminCard
            icon={PrimeIcons.CAR}
            title={intl.formatMessage({ id: "PlannedTransports" })}
            actions={actions}
            content={content} />
    );
};