import * as React from 'react';
import { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminLayout } from './components/admin/layout/AdminLayout';
import AdminRoutes from './components/admin/routing/AdminRoutes';
import AuthorizeRoute from './components/auth/AuthorizeRoute';
import AuthRoutes from './components/auth/routing/AuthRoutes';
import { HomeLayout } from './components/home/layout/HomeLayout';
import HomeRoutes from './components/home/routing/HomeRoutes';
import { PageNotFound } from './components/PageNotFound';


export type AppRoute = {
    index?: boolean,
    requireAuth?: boolean,
    requireRole?: string,
    path?: string,
    element: JSX.Element
}

export class AppRouting extends Component {

    showRoutes(routes: AppRoute[]) {
        return routes.map((route, idx) => <Route key={idx} path={route.path} element={this.getRouteElement(route)} />);
    }

    getRouteElement(route: AppRoute): JSX.Element {
        const { element, requireAuth, ...rest } = route;
        return requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element;
    }

    render() {

        return (
            <Routes>
                <Route element={<HomeLayout />}>
                    {this.showRoutes(HomeRoutes)}
                    {this.showRoutes(AuthRoutes)}
                </Route>
                <Route element={<AdminLayout />}>
                    {this.showRoutes(AdminRoutes)}
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        );
    }
}
