import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../api/ApiService';
import { RequestDetailsViewModel } from '../../api/models/RequestDetailsViewModel';
import { RequestMatchDetailsViewModel } from '../../api/models/RequestMatchDetailsViewModel';
import { TransportType } from '../../api/models/TransportType';
import { AdminCard, LoadingStatus } from '../shared/AdminCard';
import { Ellipsis } from '../shared/Ellipsis';
import { NotFoundError } from '../shared/errors/NotFoundError';
import { FormRow } from '../shared/FormRow';
import { FormRowSkeleton } from '../shared/FormRowSkeleton';
import { RouteData } from '../shared/maps/elements/RouteComponent';
import { RouteViewer } from '../shared/maps/RouteViewer';
import { Multiline } from '../shared/Multiline';
import { RouteWaypointBreadCrumb } from '../shared/RouteWaypointBreadCrumb';
import { TimeWindowDescription } from '../shared/TimeWindowDescription';
import { TransportTypeDescription } from '../shared/TransportTypeDescription';
import { VisibilityMap } from './helpers/VisibilityMap';
import { AdminPage } from './routing/AdminRoutes';

export const ShowRequest = () => {
    const { id } = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const apiService = new ApiService();
    const [status, setStatus] = useState(LoadingStatus.Loading);
    const [model, setModel] = useState<RequestDetailsViewModel>();
    const [routes, setRoutes] = useState([RouteData.createEmpty()]);
    const [routeVisibility, setRouteVisibility] = useState(new VisibilityMap(id));

    const updateRoutes = (data: RequestDetailsViewModel) => {
        setRoutes([
            RouteData.createFrom(data.waypoints, data.path, null, routeVisibility.of(data.id), 0),
            ...data.matches.map((e, idx) => RouteData.createFrom(e.matchWaypoints, e.matchPath, null, routeVisibility.of(e.id), idx + 1)),
            ...data.matches.filter(e => e.type === TransportType.Combined).map((e, idx) => RouteData.createFrom(e.transportWaypoints, e.transportPath, null, routeVisibility.of(e.transportId), idx + data.matches.length + 1))
        ]);
    }

    const setVisibility = (id: string, visible: boolean, data: RequestDetailsViewModel) => {
        routeVisibility.set(id, visible);
        setRouteVisibility(routeVisibility);
        updateRoutes(data);
    }

    useEffect(() => {
        apiService.getRequest(id)
            .then(response => {
                setModel(response);
                setVisibility(response.id, true, response);
                updateRoutes(response);
                setStatus(LoadingStatus.Loaded);
            })
            .catch(error => {
                if (error instanceof NotFoundError) {
                    setStatus(LoadingStatus.NotFound);
                } else {
                    console.error(error);
                    setStatus(LoadingStatus.Failed);
                }
            });
    }, [id]);

    const skeleton = <>
        <FormRowSkeleton labelWidth={"6rem"} contentWidth={"2rem"} />
        <FormRowSkeleton labelWidth={"4rem"} contentWidth={"6rem"} />
        <FormRowSkeleton labelWidth={"4rem"} contentHeight={"6rem"} />
        <FormRowSkeleton labelWidth={"4rem"} contentWidth={"6rem"} />
        <FormRowSkeleton labelWidth={"3rem"} contentWidth={"20rem"} />
        <FormRowSkeleton labelWidth={"4rem"} contentWidth={"3rem"} />
        <FormRowSkeleton labelWidth={"0"} contentHeight={"500px"} />
    </>

    const content = <>
        {model && <>
            <FormRow label={intl.formatMessage({ id: "DateSubmitted" })}>
                {intl.formatDate(model.dateSubmitted)}
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "PhoneNumber" })}>
                {model.phoneNumber || '-'}
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "PayloadDescription" })}>
                <Multiline value={model.description || '-'} />
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "TimeWindow" })}>
                <TimeWindowDescription timeWindow={model.timeWindow} />
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "Route" })}>
                <RouteWaypointBreadCrumb waypoints={model.waypoints} />
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "RouteLength" })}>
                {model.length} km
            </FormRow>

            <FormRow>
                <RouteViewer
                    googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    routes={routes}
                    isReadonly={true}
                    onLoading={<i className={`${PrimeIcons.SPINNER} pi-spin`} style={{ fontSize: '2rem' }} />}
                    onLoadingError={<Message severity="error" text={intl.formatMessage({ id: "UnexpectedErrorOccurredWhenLoadingGoogleMaps" })} />}
                />
            </FormRow>
        </>}
    </>

    const matches = <>
        {model && <DataTable value={model.matches}>
            <Column
                field="type"
                header={intl.formatMessage({ id: "MatchType" })}
                body={(row: RequestMatchDetailsViewModel) => <TransportTypeDescription type={row.type} />} />

            <Column
                field="datePlanned"
                header={intl.formatMessage({ id: "DatePlanned" })}
                body={(row: RequestMatchDetailsViewModel) => row.datePlanned ? intl.formatDate(row.datePlanned) : '-'} />

            <Column
                field="description"
                header={intl.formatMessage({ id: "TransportDescription" })}
                body={(row: RequestMatchDetailsViewModel) => <Ellipsis value={row.description || '-'} />} />

            <Column
                field="route"
                header={intl.formatMessage({ id: "Route" })}
                body={(row: RequestMatchDetailsViewModel) => <RouteWaypointBreadCrumb waypoints={row.type === TransportType.Individual ? row.matchWaypoints : row.transportWaypoints} />} />

            <Column
                field="routeLength"
                header={intl.formatMessage({ id: "RouteLength" })}
                body={(row: RequestMatchDetailsViewModel) => `${row.totalLength} km`} />

            <Column
                field="routeElongation"
                header={intl.formatMessage({ id: "RouteElongation" })}
                body={(row: RequestMatchDetailsViewModel) => row.transportElongation ? `${row.transportElongation}%` : '-'} />

            <Column
                field="calculatedPrice"
                header={intl.formatMessage({ id: "CalculatedPrice" })}
                body={(row: RequestMatchDetailsViewModel) => `${row.calculatedPrice} zł`} />

            <Column
                header={intl.formatMessage({ id: "Map" })}
                body={(row: RequestMatchDetailsViewModel) =>
                    <>
                        <Checkbox
                            onChange={e => setVisibility(row.id, e.checked, model)}
                            checked={routeVisibility.of(row.id)}
                            className="mr-1"
                            tooltipOptions={{ position: "top" }}
                            tooltip={intl.formatMessage({ id: "CombinedRouteTooltip" })} />

                        <Checkbox
                            onChange={e => setVisibility(row.transportId, e.checked, model)}
                            disabled={!row.transportId}
                            checked={routeVisibility.of(row.transportId)}
                            tooltipOptions={{ position: "top" }}
                            tooltip={intl.formatMessage({ id: "TransportRouteTooltip" })} />
                    </>} />

            <Column
                style={{ textAlign: 'center' }}
                body={(row: RequestMatchDetailsViewModel) => row.transportId &&
                    <Button
                        className="p-button-sm"
                        icon={PrimeIcons.SEARCH}
                        tooltipOptions={{ position: "top" }}
                        tooltip={intl.formatMessage({ id: "Show" })}
                        onClick={() => navigate(AdminPage.ShowTransport(row.transportId))} />} />
        </DataTable>}
    </>

    return (
        <>
            <AdminCard
                icon={PrimeIcons.SHOPPING_CART}
                title={intl.formatMessage({ id: "TransportRequest" })}
                skeleton={skeleton}
                content={content}
                status={status} />

            <AdminCard
                title={intl.formatMessage({ id: "MatchesCounter" }, { count: model?.matches?.length })}
                visible={model?.matches?.length > 0}
                content={matches}
                status={status} />
        </>
    );
};