import { InputText, InputTextProps } from "primereact/inputtext";
import * as React from 'react';
import { useRef, useState } from "react";
import { SearchComponent } from "./elements/SearchComponent";
import { WaypointData } from "./elements/WaypointComponent";

export interface WaypointSelectorProps extends InputTextProps {
    waypointData: WaypointData,
    onWaypointChanged: (data: WaypointData) => void
}

export const WaypointSelector = (props: WaypointSelectorProps) => {
    const ref = useRef(null);
    const [waypoint, setWaypoint] = useState(props.waypointData);
    const [title, setTitle] = useState(props.waypointData.title);

    const { waypointData, onWaypointChanged, onChange, onBlur, value, ...rest } = props;

    const onPlaceChanged = (place?: google.maps.places.PlaceResult) => {
        const data = place ? WaypointData.createFromPlace(place) : WaypointData.createEmpty();
        setWaypoint(data);
        setTitle(data.title);
        props.onWaypointChanged(data);
    }

    return (
        <>
            <InputText
                ref={ref}
                onChange={(e) => { setTitle(e.target.value); props.onChange(e); }}
                onBlur={(e) => { if (title !== waypoint.title) setTitle(waypoint.title); props.onBlur(e); }}
                value={title || ''}
                {...rest} />

            <SearchComponent
                inputRef={ref}
                onPlaceChanged={onPlaceChanged} />
        </>
    );
}