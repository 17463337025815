import { PrimeIcons } from 'primereact/api';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { AdminPage } from '../../admin/routing/AdminRoutes';
import { LoginMenu } from '../../auth/LoginMenu';
import { NavMenu, NavMenuIcon } from '../../shared/NavMenu';
import { HomePage } from '../routing/HomeRoutes';
import { HomeLogo } from './HomeLogo';


export const HomeLayout = () => {
    const intl = useIntl();

    return (
        <div className="min-h-screen flex flex-column relative flex-auto">
            <div className="flex surface-section shadow-2 relative border-bottom-1 surface-border" style={{ height: "60px" }}>
                <div className="flex justify-content-between flex-auto p-container">
                    <HomeLogo />
                    <NavMenu>
                        <NavMenuIcon to={HomePage.AboutUs()} icon={PrimeIcons.INFO_CIRCLE} label={intl.formatMessage({ id: "AboutUs" })} />
                        <NavMenuIcon to={HomePage.Contact()} icon={PrimeIcons.BOOK} label={intl.formatMessage({ id: "Contact" })} />
                        <NavMenuIcon to={AdminPage.Dashboard()} icon={PrimeIcons.MICROSOFT} label={intl.formatMessage({ id: "Admin" })} />
                        <LoginMenu />
                    </NavMenu>
                </div>
            </div>
            <div className="pt-5 p-container">
                <Outlet />
            </div>
        </div>
    );
}