import { ChangeEvent, RefObject, useEffect, useState } from "react";

interface SearchComponentProps {
    inputRef: RefObject<HTMLInputElement>,
    onPlaceChanged: (e?: google.maps.places.PlaceResult) => void
}

export const SearchComponent = (props: SearchComponentProps): any => {
    const [search, setSearch] = useState<google.maps.places.SearchBox>();

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) {
            props.onPlaceChanged(null);
        }
    };

    const onBlur = (e: FocusEvent, search: google.maps.places.SearchBox) => {
        const places = search.getPlaces();
        if (places && places.length > 0) {
            const current = search.getPlaces()[0];
            const target = e.target as HTMLInputElement;
            if (target.value && target.value !== current.formatted_address) {
                props.onPlaceChanged(current);
            }
        }
    }

    const onPlaceChanged = (input: HTMLInputElement, search: google.maps.places.SearchBox) => {
        const places = search.getPlaces();
        if (places.length !== 1 || !places[0].geometry || !places[0].geometry.location) {
            props.onPlaceChanged(null);
        } else {
            props.onPlaceChanged(places[0]);
        }
    }

    const initialize = (input: HTMLInputElement): google.maps.places.SearchBox => {
        const searchBox = new google.maps.places.SearchBox(input);
        input.addEventListener("change", e => onChange(e as unknown as ChangeEvent<HTMLInputElement>));
        input.addEventListener("blur", e => onBlur(e, searchBox));
        searchBox.addListener("places_changed", () => onPlaceChanged(input, searchBox));

        return searchBox;
    }

    useEffect(() => {
        if (typeof google === 'undefined') {
            return;
        }

        if (!search && props.inputRef) {
            const searchBox = initialize(props.inputRef.current);
            setSearch(searchBox);
        }

    }, [search, props]);

    return null;
}