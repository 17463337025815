import * as React from 'react';
import { Skeleton } from 'primereact/skeleton';

export type FormRowSkeletonProps = {
    labelWidth?: string,
    contentWidth?: string
    contentHeight?: string
}

export const FormRowSkeleton = (props: FormRowSkeletonProps) => {
    return (
        <div className="field grid">
            <label className="col-12 mb-2 md:col-2 md:mb-0 md:justify-content-end md:text-right align-items-start">
                <Skeleton width={props.labelWidth} />
            </label>
            <div className="col-12 md:col-10">
                <Skeleton width={props.contentWidth} height={props.contentHeight} />
            </div>
        </div>
    );
}