import { PrimeIcons } from 'primereact/api';
import * as React from 'react';
import { Link } from 'react-router-dom';

type AdminSidebarTogglerProps = {
    onClick: () => void
}

export const AdminSidebarToggler = (props: AdminSidebarTogglerProps) => {
    return (
        <Link to="#" className="cursor-pointer block lg:hidden text-700 mr-3" onClick={() => props.onClick()} >
            <i className={`${PrimeIcons.BARS} text-4xl`} />
        </Link>
    );
};