import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HomePage } from '../routing/HomeRoutes';

export const HomeLogo = () => {
    return (
        <Link to={HomePage.Home()} className="py-2 text-3xl text-bluegray-700 hover:text-bluegray-900 font-medium no-underline">
            <FormattedMessage id="HorseCare" />
        </Link>
    );
};