import { useEffect, useState } from "react";
import { Coordinates } from "./Coordinates";
import { GoogleMapWrapper } from "./GoogleMapWrapper";

export class BufferData {
    points: Coordinates[];
    isCustom: boolean;
    isCorrect: boolean;

    constructor(points: Coordinates[], isCustom: boolean, isCorrect: boolean) {
        this.points = points;
        this.isCustom = isCustom;
        this.isCorrect = isCorrect;
    }

    static createEmpty(): BufferData {
        return new BufferData([], false, false);
    }

    static createFrom(buffer: Coordinates[]): BufferData {
        return new BufferData(buffer, false, true);
    }

    static createFromCalculation(points: Coordinates[]): BufferData {
        return new BufferData(points, false, true);
    }

    static createFromDrawing(points: Coordinates[], isCorrect: boolean): BufferData {
        return new BufferData(points, true, isCorrect);
    }

    isValid(): boolean {
        return this.points?.length > 2 ?? false;
    }
}

export interface BufferProps {
    map: GoogleMapWrapper,
    data: BufferData,
    settings: google.maps.PolygonOptions
}

export const BufferComponent = (props: BufferProps): any => {
    const [polygon, setPolygon] = useState<google.maps.Polygon>();

    const isValid = () => {
        return polygon && props.data.isValid();
    }

    const draw = (map: GoogleMapWrapper) => {
        if (isValid()) {
            polygon.setOptions({
                paths: props.data.points,
                strokeColor: props.settings.strokeColor,
                strokeOpacity: props.settings.strokeOpacity,
                strokeWeight: props.settings.strokeWeight,
                fillColor: props.settings.fillColor,
                fillOpacity: props.settings.fillOpacity
            });
            map.set(polygon);
        } else {
            map.unset(polygon);
        }
    }

    useEffect(() => {
        if (!polygon) {
            setPolygon(new google.maps.Polygon());
        }
        return () => props.map.unset(polygon);
    }, [polygon]);

    useEffect(() => {
        draw(props.map);
    }, [polygon, props]);

    return null;
}