import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const NotAuthorizedContent = () => {
    return (
        <div className="flex flex-column flex-wrap align-items-center justify-content-between">
            <img className="w-3 " src="/img/404.png" />
            <p className="font-semibold text-xl text-bluegray-800 vertical-align-middle">
                <FormattedMessage id="PageNotAuthorized"></FormattedMessage>
            </p>
        </div>
    );
}