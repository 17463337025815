import { PrimeIcons } from 'primereact/api';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { AdminCard } from '../shared/AdminCard';

export const Dashboard = () => {
    const intl = useIntl();

    return (
        <AdminCard
            icon={PrimeIcons.MICROSOFT}
            title={intl.formatMessage({ id: "Dashboard" })} />
    );
};