import { PrimeIcons } from 'primereact/api';
import { Sidebar } from 'primereact/sidebar';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Link, To } from 'react-router-dom';
import { AdminPage } from '../routing/AdminRoutes';
import { AdminLogo } from './AdminLogo';
import "./AdminSidebar.tsx.css";

type AdminSidebarProps = {
    visible: boolean,
    onHide: () => void
}

export const AdminSidebar = (props: AdminSidebarProps) => {
    return (
        <>
            <div className="bg-bluegray-800 h-screen lg:block flex-shrink-0 absolute lg:sticky top-0 z-1 select-none hidden z-5" style={{ width: "280px" }}>
                <AdminSidebarContent onHide={props.onHide} />
            </div>

            <Sidebar visible={props.visible} onHide={() => props.onHide()} showCloseIcon={false} className="p-admin-sidebar bg-bluegray-800">
                <AdminSidebarContent onHide={props.onHide} />
            </Sidebar>
        </>
    );
};

type AdminSidebarContentProps = {
    onHide: () => void
}

const AdminSidebarContent = (props: AdminSidebarContentProps) => {
    const intl = useIntl();

    return (
        <div className="flex flex-column h-full">
            <div className="flex px-3 bg-bluegray-900 flex-shrink-0">
                <AdminLogo />
            </div>
            <div className="overflow-y-auto mt-0">
                <ul className="list-none p-1 m-0">
                    <AdminSidebarLink to={AdminPage.Dashboard()} onClick={() => props.onHide()} icon={PrimeIcons.MICROSOFT} label={intl.formatMessage({ id: "Dashboard" })} />
                    <AdminSidebarLink to={AdminPage.Transports()} onClick={() => props.onHide()} icon={PrimeIcons.CAR} label={intl.formatMessage({ id: "PlannedTransports" })} />
                    <AdminSidebarLink to={AdminPage.Requests()} onClick={() => props.onHide()} icon={PrimeIcons.SHOPPING_CART} label={intl.formatMessage({ id: "TransportRequests" })} />
                    <AdminSidebarLink to={AdminPage.Settings()} onClick={() => props.onHide()} icon={PrimeIcons.COG} label={intl.formatMessage({ id: "Settings" })} />
                </ul>
            </div>
        </div>
    );
};

type AdminSidebarLinkProps = {
    icon: string,
    label: string,
    to: To,
    onClick: () => void
};

const AdminSidebarLink = (props: AdminSidebarLinkProps) => {
    return (
        <li>
            <Link to={props.to} onClick={() => props.onClick()} className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 no-underline transition-duration-150 transition-colors w-full">
                <i className={`${props.icon} mr-2`}></i>
                <span className="font-medium">{props.label}</span>
            </Link>
        </li>
    );
};