import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Message } from 'primereact/message';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../api/ApiService';
import { TransportDetailsViewModel } from '../../api/models/TransportDetailsViewModel';
import { TransportMatchDetailsViewModel } from '../../api/models/TransportMatchDetailsViewModel';
import { AdminCard, LoadingStatus } from '../shared/AdminCard';
import { Ellipsis } from '../shared/Ellipsis';
import { NotFoundError } from '../shared/errors/NotFoundError';
import { FormRow } from '../shared/FormRow';
import { FormRowSkeleton } from '../shared/FormRowSkeleton';
import { useToastContext } from '../shared/hooks/useToastContext';
import { RouteData } from '../shared/maps/elements/RouteComponent';
import { RouteViewer } from '../shared/maps/RouteViewer';
import { Multiline } from '../shared/Multiline';
import { RouteWaypointBreadCrumb } from '../shared/RouteWaypointBreadCrumb';
import { YesNoDescription } from '../shared/YesNoDescription';
import { VisibilityMap } from './helpers/VisibilityMap';
import { AdminPage } from './routing/AdminRoutes';

export const ShowTransport = () => {
    const { id } = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const toast = useToastContext();
    const apiService = new ApiService();

    const [status, setStatus] = useState(LoadingStatus.Loading);
    const [model, setModel] = useState<TransportDetailsViewModel>();
    const [routes, setRoutes] = useState([RouteData.createEmpty()]);
    const [routeVisibility, setRouteVisibility] = useState(new VisibilityMap(id));

    const updateRoutes = (data: TransportDetailsViewModel) => {
        setRoutes([
            RouteData.createFrom(data.waypoints, data.path, data.buffer, routeVisibility.of(data.id), 0),
            ...data.matches.map((e, idx) => RouteData.createFrom(e.matchWaypoints, e.matchPath, null, routeVisibility.of(e.id), idx + 1)),
            ...data.matches.map((e, idx) => RouteData.createFrom(e.requestWaypoints, e.requestPath, null, routeVisibility.of(e.requestId), idx + data.matches.length + 1))
        ]);
    }

    const setVisibility = (id: string, visible: boolean, data: TransportDetailsViewModel) => {
        routeVisibility.set(id, visible);
        setRouteVisibility(routeVisibility);
        updateRoutes(data);
    }

    const toggleAvailability = () => {
        (model.isAvailable ? apiService.disableTransport(model.id) : apiService.enableTransport(model.id))
            .then(response => {
                setModel({ ...model, isAvailable: response.isAvailable });
                toast.showSuccess({ detail: intl.formatMessage({ id: response.isAvailable ? "RouteSuccessfullySetToAvailable" : "RouteSuccessfullySetToUnavailable" }) });
            })
            .catch(error => {
                console.log(error);
                toast.showGenericError();
            });
    }

    useEffect(() => {
        apiService.getTransport(id)
            .then(response => {
                setModel(response);
                setVisibility(response.id, true, response);
                updateRoutes(response);
                setStatus(LoadingStatus.Loaded);
            })
            .catch(error => {
                if (error instanceof NotFoundError) {
                    setStatus(LoadingStatus.NotFound);
                } else {
                    console.error(error);
                    setStatus(LoadingStatus.Failed);
                }
            });
    }, [id]);

    const actions = <>
        {model && <span>
            <Button
                className="p-button-sm"
                icon={PrimeIcons.PENCIL}
                label={intl.formatMessage({ id: "Edit" })}
                onClick={() => navigate(AdminPage.EditTransport(id))}
            />

            <Button
                className="p-button-sm ml-1"
                icon={model.isAvailable ? PrimeIcons.EYE_SLASH : PrimeIcons.EYE}
                label={intl.formatMessage({ id: model.isAvailable ? "MakeUnavailable" : "MakeAvailable" })}
                onClick={toggleAvailability}
            />
        </span>}
    </>

    const skeleton = <>
        <FormRowSkeleton labelWidth={"4rem"} contentWidth={"2rem"} />
        <FormRowSkeleton labelWidth={"3rem"} contentHeight={"6rem"} />
        <FormRowSkeleton labelWidth={"6rem"} contentWidth={"4rem"} />
        <FormRowSkeleton labelWidth={"3rem"} contentWidth={"20rem"} />
        <FormRowSkeleton labelWidth={"6rem"} contentWidth={"2rem"} />
        <FormRowSkeleton labelWidth={"0"} contentHeight={"500px"} />
    </>

    const content = <>
        {model && <>
            <FormRow label={intl.formatMessage({ id: "IsAvailable" })}>
                <YesNoDescription value={model.isAvailable} />
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "TransportDescription" })}>
                <Multiline value={model.description || '-'} />
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "DatePlanned" })}>
                {model.datePlanned ? intl.formatDate(model.datePlanned) : '-'}
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "Route" })}>
                <RouteWaypointBreadCrumb waypoints={model.waypoints} />
            </FormRow>

            <FormRow label={intl.formatMessage({ id: "RouteLength" })}>
                {model.length} km
            </FormRow>

            <FormRow>
                <RouteViewer
                    googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    routes={routes}
                    isReadonly={true}
                    onLoading={<i className={`${PrimeIcons.SPINNER} pi-spin`} style={{ fontSize: '2rem' }} />}
                    onLoadingError={<Message severity="error" text={intl.formatMessage({ id: "UnexpectedErrorOccurredWhenLoadingGoogleMaps" })} />}
                />
            </FormRow>
        </>}
    </>

    const matches = <>
        {model &&
            <DataTable value={model.matches}>
                <Column
                    field="description"
                    header={intl.formatMessage({ id: "PayloadDescription" })}
                    body={(row: TransportMatchDetailsViewModel) => <Ellipsis value={row.description || '-'} />} />

                <Column
                    field="dateSubmitted"
                    header={intl.formatMessage({ id: "DateSubmitted" })}
                    body={(row: TransportMatchDetailsViewModel) => row.dateSubmitted ? intl.formatDate(row.dateSubmitted) : '-'} />

                <Column
                    field="route"
                    header={intl.formatMessage({ id: "Route" })}
                    body={(row: TransportMatchDetailsViewModel) => <RouteWaypointBreadCrumb waypoints={row.requestWaypoints} />} />

                <Column
                    field="customerRouteLength"
                    header={intl.formatMessage({ id: "CustomerRouteLength" })}
                    body={(row: TransportMatchDetailsViewModel) => `${row.length} km`} />

                <Column
                    field="combinedRouteLength"
                    header={intl.formatMessage({ id: "CombinedRouteLength" })}
                    body={(row: TransportMatchDetailsViewModel) => `${row.totalLength} km`} />

                <Column
                    field="routeElongation"
                    header={intl.formatMessage({ id: "RouteElongation" })}
                    body={(row: TransportMatchDetailsViewModel) => `${row.elongation}%`} />

                <Column
                    field="routeLength"
                    header={intl.formatMessage({ id: "CalculatedPrice" })}
                    body={(row: TransportMatchDetailsViewModel) => `${row.calculatedPrice} zł`} />

                <Column
                    header={intl.formatMessage({ id: "Map" })}
                    body={(row: TransportMatchDetailsViewModel) =>
                        <>
                            <Checkbox
                                onChange={e => setVisibility(row.requestId, e.checked, model)}
                                checked={routeVisibility.of(row.requestId)}
                                className="mr-1"
                                tooltipOptions={{ position: "top" }}
                                tooltip={intl.formatMessage({ id: "CustomerRouteTooltip" })} />

                            <Checkbox
                                onChange={e => setVisibility(row.id, e.checked, model)}
                                checked={routeVisibility.of(row.id)}
                                tooltipOptions={{ position: "top" }}
                                tooltip={intl.formatMessage({ id: "CombinedRouteTooltip" })} />
                        </>} />

                <Column
                    style={{ textAlign: 'center' }}
                    body={(row: TransportMatchDetailsViewModel) =>
                        <Button
                            className="p-button-sm"
                            icon={PrimeIcons.SEARCH}
                            tooltipOptions={{ position: "top" }}
                            tooltip={intl.formatMessage({ id: "Show" })}
                            onClick={() => navigate(AdminPage.ShowRequest(row.requestId))} />} />
            </DataTable>}
    </>

    return (
        <>
            <AdminCard
                icon={PrimeIcons.CAR}
                title={intl.formatMessage({ id: "PlannedTransport" })}
                actions={actions}
                skeleton={skeleton}
                content={content}
                status={status} />

            <AdminCard
                title={intl.formatMessage({ id: "MatchesCounter" }, { count: model?.matches?.length })}
                visible={model?.matches?.length > 0}
                content={matches}
                status={status} />
        </>
    );
};