import * as React from 'react';
import { AdminCard } from '../../shared/AdminCard';
import { NotFoundContent } from '../../shared/NotFoundContent';

export const NotFound = () => {
    return (
        <AdminCard>
            <NotFoundContent />
        </AdminCard>
    );
};