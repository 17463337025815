import * as React from 'react';
import { AppRoute } from '../../../AppRouting';
import { Login } from '../Login';
import { Logout } from '../Logout';
import { ApplicationPaths, LoginActions, LogoutActions } from './AuthConstants';

const ApiAuthorizationRoutes: AppRoute[] = [
    {
        path: ApplicationPaths.Login,
        element: loginAction(LoginActions.Login)
    },
    {
        path: ApplicationPaths.LoginFailed,
        element: loginAction(LoginActions.LoginFailed)
    },
    {
        path: ApplicationPaths.LoginCallback,
        element: loginAction(LoginActions.LoginCallback)
    },
    {
        path: ApplicationPaths.Profile,
        element: loginAction(LoginActions.Profile)
    },
    {
        path: ApplicationPaths.Register,
        element: loginAction(LoginActions.Register)
    },
    {
        path: ApplicationPaths.LogOut,
        element: logoutAction(LogoutActions.Logout)
    },
    {
        path: ApplicationPaths.LogOutCallback,
        element: logoutAction(LogoutActions.LogoutCallback)
    },
    {
        path: ApplicationPaths.LoggedOut,
        element: logoutAction(LogoutActions.LoggedOut)
    }
];

function loginAction(name: string): JSX.Element {
    return <Login action={name}></Login>;
}

function logoutAction(name: string): JSX.Element {
    return <Logout action={name}></Logout>;
}

export default ApiAuthorizationRoutes;
