export class VisibilityMap {
    #store: { [key: string]: boolean; } = {};
    #defaultKey: string;

    constructor(defaultKey: string) {
        this.#defaultKey = defaultKey;
    }

    of(key: string) {
        return this.#store[key] || false;
    }

    set(key: string, value: boolean) {
        Object.keys(this.#store).forEach(key => this.#store[key] = false);
        this.#store[key] = value;
        if (Object.values(this.#store).indexOf(true) === -1)
            this.#store[this.#defaultKey] = true;
    }
}
