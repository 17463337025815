import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AdminPage } from '../routing/AdminRoutes';

export const AdminLogo = () => {
    return (
        <Link to={AdminPage.Dashboard()} className="py-3 text-lg text-bluegray-100 hover:text-bluegray-50 font-medium no-underline">
            <FormattedMessage id="HorseCare" /> | <strong><FormattedMessage id="Admin" /></strong>
        </Link>
    );
};