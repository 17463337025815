import * as React from 'react';
import { AppRoute } from '../../../AppRouting';
import { Dashboard } from '../Dashboard';
import { NotFound } from '../generic/NotFound';
import { Requests } from '../Requests';
import { SaveTransport } from '../SaveTransport';
import { Settings } from '../Settings';
import { ShowRequest } from '../ShowRequest';
import { ShowTransport } from '../ShowTransport';
import { Transports } from '../Transports';

const prefix = '/Admin';

export const AdminPaths = {
    AdminPrefix: prefix,
    Dashboard: `${prefix}`,
    Requests: `${prefix}/Requests`,
    ShowRequest: `${prefix}/Requests/:id`,
    Settings: `${prefix}/Settings`,
    Transports: `${prefix}/Transports`,
    AddTransport: `${prefix}/Transports/Add`,
    EditTransport: `${prefix}/Transports/:id/Edit`,
    ShowTransport: `${prefix}/Transports/:id`,
    NotFound: `${prefix}/*`
};

export const AdminPage = {
    Dashboard: () => AdminPaths.Dashboard,
    Requests: () => AdminPaths.Requests,
    ShowRequest: (id: string) => AdminPaths.ShowRequest.replace(':id', id),
    Settings: () => AdminPaths.Settings,
    Transports: () => AdminPaths.Transports,
    AddTransport: () => AdminPaths.AddTransport,
    EditTransport: (id: string) => AdminPaths.EditTransport.replace(':id', id),
    ShowTransport: (id: string) => AdminPaths.ShowTransport.replace(':id', id)
};

const AdminRoutes: AppRoute[] = [
    {
        path: AdminPaths.Dashboard,
        requireAuth: true,
        requireRole: "Admin",
        element: <Dashboard />
    },
    {
        path: AdminPaths.Requests,
        requireAuth: true,
        requireRole: "Admin",
        element: <Requests />
    },
    {
        path: AdminPaths.ShowRequest,
        requireAuth: true,
        requireRole: "Admin",
        element: <ShowRequest />
    },
    {
        path: AdminPaths.Settings,
        requireAuth: true,
        requireRole: "Admin",
        element: <Settings />
    },
    {
        path: AdminPaths.Transports,
        requireAuth: true,
        requireRole: "Admin",
        element: <Transports />
    },
    {
        path: AdminPaths.AddTransport,
        requireAuth: true,
        requireRole: "Admin",
        element: <SaveTransport />
    },
    {
        path: AdminPaths.EditTransport,
        requireAuth: true,
        requireRole: "Admin",
        element: <SaveTransport />
    },
    {
        path: AdminPaths.ShowTransport,
        requireAuth: true,
        requireRole: "Admin",
        element: <ShowTransport />
    },
    {
        path: AdminPaths.NotFound,
        requireAuth: true,
        requireRole: "Admin",
        element: <NotFound />
    }
];

export default AdminRoutes;
