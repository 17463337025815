import { NotFoundError } from "../components/shared/errors/NotFoundError";
import { ServerError } from "../components/shared/errors/ServerError";
import { ValidationError } from "../components/shared/errors/ValidationError";
import { Coordinates } from "../components/shared/maps/elements/Coordinates";
import { AskForTransportCommand } from "./models/AskForTransportCommand";
import { AskForTransportResponse } from "./models/AskForTransportResponse";
import { ChangeTransportAvailabilityResponse } from "./models/ChangeTransportAvailabilityResponse";
import { ConfigurationViewModel } from "./models/ConfigurationViewModel";
import { RequestDetailsViewModel } from "./models/RequestDetailsViewModel";
import { RequestListingViewModel } from "./models/RequestListingViewModel";
import { SaveConfigurationCommand } from "./models/SaveConfigurationCommand";
import { SaveTransportCommand } from "./models/SaveTransportCommand";
import { TransportDetailsViewModel } from "./models/TransportDetailsViewModel";
import { TransportListingViewModel } from "./models/TransportListingViewModel";

export default class ApiService {

    getRequest(id: string): Promise<RequestDetailsViewModel> {
        return this.#get(`/api/requests/${id}`);
    }

    getRequests(params: URLSearchParams): Promise<RequestListingViewModel> {
        return this.#get(`/api/requests?${params.toString()}`);
    }

    getTransport(id: string): Promise<TransportDetailsViewModel> {
        return this.#get(`/api/transports/${id}`);
    }

    getTransports(params: URLSearchParams): Promise<TransportListingViewModel> {
        return this.#get(`/api/transports?${params.toString()}`);
    }

    saveTransport(command: SaveTransportCommand): Promise<string> {
        return this.#post(`/api/transports`, command);
    }

    askForTransport(command: AskForTransportCommand): Promise<AskForTransportResponse> {
        return this.#post(`/api/transports/ask`, command);
    }

    enableTransport(id: string): Promise<ChangeTransportAvailabilityResponse> {
        return this.#post(`/api/transports/${id}/enable`, {});
    }

    disableTransport(id: string): Promise<ChangeTransportAvailabilityResponse> {
        return this.#post(`/api/transports/${id}/disable`, {});
    }

    calculateBuffer(points: Coordinates[], radius: number): Promise<Coordinates[]> {
        return this.#post(`/api/calculateBuffer`, { points, radius });
    }

    validateBuffer(points: Coordinates[]): Promise<boolean> {
        return this.#post(`/api/validateBuffer`, { points });
    }

    getConfiguration(): Promise<ConfigurationViewModel> {
        return this.#get(`/api/configuration`);
    }

    saveConfiguration(command: SaveConfigurationCommand): Promise<Response> {
        return this.#post(`/api/configuration`, command);
    }

    #get(url: string) {
        return fetch(url).then(this.#handleResponse);
    }

    #post(url: string, body: any) {
        const init: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        };

        return fetch(url, init).then(this.#handleResponse);
    }

    #handleResponse(res: Response): any {
        const contentType = res.headers.get("content-type");

        if (res.ok && contentType && contentType.indexOf("application/json") !== -1) {
            return res.json();
        } else if (res.redirected) {
            const loginUrl = '/Identity/Account/Login?ReturnUrl=';
            const loginUrlMatch = res.url.indexOf(loginUrl);
            if (loginUrlMatch > 0) {
                const loginUrlBase = res.url.substring(0, loginUrlMatch + loginUrl.length)
                window.location.href = loginUrlBase + encodeURIComponent(window.location.pathname + window.location.search);
            }
        } else if (res.ok) {
            return Promise.resolve();
        } else if (res.status === 400) {
            throw new ValidationError(res.statusText);
        } else if (res.status === 404) {
            throw new NotFoundError(res.statusText);
        } else if (res.status === 500) {
            throw new ServerError(res.statusText);
        } else {
            throw new Error(res.statusText);
        }
    }
}