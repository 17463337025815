import { PrimeIcons } from 'primereact/api';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import * as React from 'react';
import "./AdminCard.tsx.css";
import { NotFoundContent } from './NotFoundContent';
import { ServerErrorContent } from './ServerErrorContent';

export enum LoadingStatus {
    Loading,
    Loaded,
    NotFound,
    Failed
}

interface AdminCardProps {
    visible: boolean,
    icon?: string,
    title?: string,
    actions?: React.ReactNode,
    skeleton?: React.ReactNode,
    content?: React.ReactNode,
    status: LoadingStatus
}

export const AdminCard = (props: AdminCardProps) => {

    const header = (
        <>
            <div className="p-admin-card-header flex flex-column sm:flex-row justify-content-between">
                <div className="flex justify-content-start">
                    {props.icon && <h3 className="mr-3"><i className={props.icon} style={{ fontSize: '1.75rem' }} /></h3>}
                    <h3>{props.title}</h3>
                </div>
                {props.status === LoadingStatus.Loaded && <div>{props.actions}</div>}
            </div>
            <div className="p-admin-card-divider">
                <Divider />
            </div>
        </>
    )

    const loading = (
        <>
            <div className="text-center w-full my-5">
                <i className={`${PrimeIcons.SPINNER} pi-spin`} style={{ fontSize: '2rem' }} />
            </div>
        </>
    )

    return (
        <>
            {props.visible &&
                <Card header={(props.title || props.actions) && header} className="p-admin-card mb-3">
                    {props.status === LoadingStatus.Loading && (props.skeleton || loading)}
                    {props.status === LoadingStatus.NotFound && <NotFoundContent />}
                    {props.status === LoadingStatus.Failed && <ServerErrorContent />}
                    {props.status === LoadingStatus.Loaded && props.content}
                </Card>}
        </>
    );
};

AdminCard.defaultProps = {
    status: LoadingStatus.Loaded,
    visible: true
}