import * as React from 'react';

export interface MultilineProps {
    value: string
}

export const Multiline = (props: MultilineProps) => {
    return (
        <span className="white-space-preline">
            {props.value}
        </span>
    );
}