import * as React from 'react'
import { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { PageNotAuthorized } from '../PageNotAuthorized'
import authService from './AuthorizeService'
import { ApplicationPaths, QueryParameterNames } from './routing/AuthConstants'

type AuthorizeProps = {
    path?: string,
    requireRole?: string,
    element: JSX.Element
}

type AuthorizeState = {
    ready: boolean,
    isAuthenticated: boolean,
    isInRole: boolean
}

export default class AuthorizeRoute extends Component<AuthorizeProps, AuthorizeState> {
    private _subscription: number

    constructor(props: AuthorizeProps) {
        super(props);

        this.state = {
            ready: false,
            isAuthenticated: false,
            isInRole: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, isAuthenticated, isInRole } = this.state;
        var link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
        if (!ready) {
            return <div></div>;
        } else {
            const { element } = this.props;
            if (!isAuthenticated) {
                return <Navigate replace to={redirectUrl} />;
            }
            if (!isInRole) {
                return <PageNotAuthorized />
            }
            return element;
        }
    }

    async populateAuthenticationState() {
        const [isAuthenticated, isInRole] = await Promise.all([authService.isAuthenticated(), authService.isUserInRole(this.props.requireRole)]);
        this.setState({ ready: true, isAuthenticated, isInRole });
    }

    async authenticationChanged() {
        this.setState({ ready: false, isAuthenticated: false, isInRole: false });
        await this.populateAuthenticationState();
    }
}
