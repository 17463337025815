import { Toast, ToastMessage } from "primereact/toast";
import * as React from "react";
import { createContext, useContext, useRef } from "react";
import { useIntl } from "react-intl";

export interface ToastActions {
    showSuccess: (message: ToastMessage) => void,
    showGenericSuccess: () => void,
    showError: (message: ToastMessage) => void,
    showGenericError: () => void,
    showInfo: (message: ToastMessage) => void,
    showWarn: (message: ToastMessage) => void,
}

const ToastContext = createContext<ToastActions>(undefined);

export interface ToastContextProps {
    children?: React.ReactNode
}

export const ToastContextProvider = (props: ToastContextProps) => {
    const toastRef = useRef<Toast>(null);
    const intl = useIntl();

    const showToast = (message: ToastMessage) => {
        if (!toastRef.current)
            return;
        toastRef.current.show(message);
    }

    const showSuccess = (message: ToastMessage) => {
        showToast({ severity: 'success', ...message });
    }

    const showGenericSuccess = () => {
        showSuccess({ detail: intl.formatMessage({ id: "OperationSucceeded" }) });
    }

    const showWarn = (message: ToastMessage) => {
        showToast({ severity: 'warn', ...message });
    }

    const showInfo = (message: ToastMessage) => {
        showToast({ severity: 'info', ...message });
    }

    const showError = (message: ToastMessage) => {
        showToast({ severity: 'error', ...message });
    }

    const showGenericError = () => {
        showError({ detail: intl.formatMessage({ id: "UnexpectedErrorOccurred" }), sticky: true });
    }

    return (
        <ToastContext.Provider value={{ showSuccess, showGenericSuccess, showError, showGenericError, showInfo, showWarn }}>
            {props.children}
            <Toast ref={toastRef} position="bottom-right" />
        </ToastContext.Provider>
    );
};

export const useToastContext = () => {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error(
            "useToastContext have to be used within ToastContextProvider"
        );
    }

    return context;
};