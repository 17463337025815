import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import { SelectItem } from 'primereact/selectitem';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ApiService from '../../api/ApiService';
import { TimeWindow } from '../../api/models/TimeWindow';
import preventSendForm from '../../helpers/FormUtilities';
import { FormRow } from '../shared/FormRow';
import { FormRowError } from '../shared/FormRowError';
import { useToastContext } from '../shared/hooks/useToastContext';
import { RouteData } from '../shared/maps/elements/RouteComponent';
import { WaypointData } from '../shared/maps/elements/WaypointComponent';
import { RouteViewer } from '../shared/maps/RouteViewer';
import { WaypointSelector } from '../shared/maps/WaypointSelector';
import { MessageDialog, MessageDialogProps } from '../shared/MessageDialog';

interface AskForTransportForm {
    description: string,
    timeWindow: TimeWindow,
    phoneNumber: string,
    origin: string,
    destination: string
};

export const AskForTransport = () => {
    const intl = useIntl();
    const toast = useToastContext();
    const apiService = new ApiService();
    const [message, setMessage] = useState<MessageDialogProps>({ isVisible: false });
    const [routes, setRoutes] = useState([RouteData.createEmpty()]);

    let values: AskForTransportForm = {
        phoneNumber: '',
        description: '',
        timeWindow: TimeWindow.Any,
        origin: '',
        destination: ''
    };

    const timeWindows: SelectItem[] = [
        { value: TimeWindow.WithinWeek, label: intl.formatMessage({ id: "TimeWindow_WithinWeek" }) },
        { value: TimeWindow.WithinTwoWeeks, label: intl.formatMessage({ id: "TimeWindow_WithinTwoWeeks" }) },
        { value: TimeWindow.Any, label: intl.formatMessage({ id: "TimeWindow_Any" }) }
    ];

    const validate = (values: AskForTransportForm) => {
        const errors: FormikErrors<AskForTransportForm> = {};
        if (!values.phoneNumber) {
            errors.phoneNumber = intl.formatMessage({ id: "FieldRequired" });
        }
        if (!values.description) {
            errors.description = intl.formatMessage({ id: "FieldRequired" });
        }
        if (!values.origin) {
            errors.origin = intl.formatMessage({ id: "FieldRequired" });
        }
        if (!values.destination) {
            errors.destination = intl.formatMessage({ id: "FieldRequired" });
        }
        return errors;
    }

    const onSubmit = (values: AskForTransportForm, form: FormikHelpers<AskForTransportForm>) => {
        if (!routes[0].isValid()) {
            setMessage({ text: intl.formatMessage({ id: "SelectValidRoute" }), severity: 'warn' });
            form.setSubmitting(false);
            return;
        }

        if (routes[0].hasDuplicatedWaypoints()) {
            setMessage({ text: intl.formatMessage({ id: "RouteContainsSameOriginAndDestination" }), severity: 'warn' });
            form.setSubmitting(false);
            return;
        }

        apiService.askForTransport({
            description: values.description,
            phoneNumber: values.phoneNumber,
            timeWindow: values.timeWindow,
            waypoints: routes[0].waypoints.map(e => e.toNamedCoordinates())
        }).then(response => {
            if (response.matchFound) {
                setMessage({ text: intl.formatMessage({ id: "AskForTransportSuccessText" }, { price: response.suggestedPrice }), severity: 'success' });
            } else {
                setMessage({ text: intl.formatMessage({ id: "AskForTransportFailureText" }), severity: 'warn' });
            }
            form.setSubmitting(false);
        }).catch(error => {
            console.error(error);
            toast.showGenericError();
            form.setSubmitting(false);
        });
    }

    const onWaypointChange = (data: WaypointData, index: number) => {
        routes[0].setWaypoint(data, index);
        setRoutes([...routes]);
    }

    return (
        <>
            <h3 className="mb-1"><FormattedMessage id="AskForTransport" /></h3>

            <Divider />

            <Formik initialValues={values} validate={validate} onSubmit={onSubmit}>
                {(form: FormikProps<AskForTransportForm>) => (
                    <Form onSubmit={form.handleSubmit} onKeyDown={preventSendForm}>
                        <FormRow label={intl.formatMessage({ id: "PhoneNumber" })} htmlFor="phoneNumber" required>
                            <InputText
                                name="phoneNumber"
                                placeholder={intl.formatMessage({ id: "PhoneNumberPlaceholder" })}
                                value={form.values.phoneNumber}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                maxLength={50}
                                className="w-full" />
                            <FormRowError name="phoneNumber" />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "PayloadDescription" })} htmlFor="description" required>
                            <InputTextarea
                                name="description"
                                placeholder={intl.formatMessage({ id: "PayloadDescriptionPlaceholder" })}
                                value={form.values.description}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                maxLength={1000}
                                className="w-full" />
                            <FormRowError name="description" />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "TimeWindow" })} htmlFor="timeWindow" required>
                            <Dropdown
                                inputId="timeWindow"
                                name="timeWindow"
                                value={form.values.timeWindow}
                                options={timeWindows}
                                placeholder={intl.formatMessage({ id: "TimeWindowPlaceholder" })}
                                onChange={(e) => form.setFieldValue('timeWindow', e.value)}
                            />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "RouteOrigin" })} htmlFor="origin" required>
                            <WaypointSelector
                                name="origin"
                                placeholder={intl.formatMessage({ id: "RouteOriginPlaceholder" })}
                                value={form.values.origin}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                className="w-full"
                                waypointData={routes[0].waypoints[0]}
                                onWaypointChanged={(data) => onWaypointChange(data, 0)} />
                            <FormRowError name="origin" />
                        </FormRow>

                        <FormRow label={intl.formatMessage({ id: "RouteDestination" })} htmlFor="destination" required>
                            <WaypointSelector
                                name="destination"
                                placeholder={intl.formatMessage({ id: "RouteDestinationPlaceholder" })}
                                value={form.values.destination}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                className="w-full"
                                waypointData={routes[0].waypoints[1]}
                                onWaypointChanged={(data) => onWaypointChange(data, 1)} />
                            <FormRowError name="destination" />
                        </FormRow>

                        <FormRow>
                            <RouteViewer
                                googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                routes={routes}
                                onLoading={<i className={`${PrimeIcons.SPINNER} pi-spin`} style={{ fontSize: '2rem' }} />}
                                onLoadingError={<Message severity="error" text={intl.formatMessage({ id: "UnexpectedErrorOccurredWhenLoadingGoogleMaps" })} />}
                                onError={(error) => setMessage({ text: error, severity: 'warn' })}
                            />
                        </FormRow>

                        <FormRow>
                            <Button
                                type="submit"
                                label={intl.formatMessage({ id: "Send" })}
                                loading={form.isSubmitting}
                                disabled={form.isSubmitting} />
                        </FormRow>
                    </Form>
                )}
            </Formik>

            <MessageDialog {...message} onHide={() => setMessage({ isVisible: false })} />
        </>
    );
}
