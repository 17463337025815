import { useEffect, useState } from "react";
import { Coordinates } from "./Coordinates";
import { GoogleMapWrapper } from "./GoogleMapWrapper";

export class PathData {
    points: Coordinates[];

    constructor(points: Coordinates[]) {
        this.points = points;
    }

    static createFrom(path: Coordinates[]): PathData {
        return new PathData(path);
    }

    static createFromDirections(directions: google.maps.DirectionsRoute): PathData {
        const points = Coordinates.createArrayFrom(directions.overview_path);
        return new PathData(points);
    }

    isValid(): boolean {
        return this.points?.length > 2 ?? false;
    }
}

export interface PathProps {
    map: GoogleMapWrapper,
    data: PathData,
    settings: google.maps.PolylineOptions
}

export const PathComponent = (props: PathProps): any => {
    const [polyline, setPolyline] = useState<google.maps.Polyline>();

    const isValid = () => {
        return !!polyline && props.data.isValid();
    }

    const draw = (map: GoogleMapWrapper) => {
        map.unset(polyline);

        if (isValid()) {
            polyline.setOptions({
                path: props.data.points,
                strokeColor: props.settings.strokeColor,
                strokeOpacity: props.settings.strokeOpacity,
                strokeWeight: props.settings.strokeWeight
            });
            map.set(polyline);
        }
    }

    useEffect(() => {
        if (!polyline) {
            setPolyline(new google.maps.Polyline());
        }
        return () => props.map.unset(polyline);
    }, [polyline]);

    useEffect(() => {
        draw(props.map);
    }, [polyline, props]);

    return null;
}